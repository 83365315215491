import { FormRow } from '@components/form-row.tsx';
import { HumanNameValidator } from '@core/validators/human-name-validator.ts';
import {
  DateInput,
  Edit,
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';

const UserEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SmaEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <TextInput name="id" source="id" disabled />
      <FormRow width="600px">
      <TextInput
        name="firstName"
        source="firstName"
        style={{ width: 300 }}
        validate={[
          required(),
          minLength(3),
          maxLength(30),
          HumanNameValidator,
        ]}
      />
      <TextInput
        name="lastName"
        source="lastName"
        style={{ width: 300 }}
        validate={[
          required(),
          minLength(3),
          maxLength(30),
          HumanNameValidator,
        ]}
      />
      </FormRow>
      <TextInput name="status" source="status" disabled />
      <DateInput name="createdAt" source="createdAt" disabled />
      <FormRow width="600px">
        <TextInput name="role" source="role" disabled style={{ width: 300 }} />
        <TextInput name="company" source="company" disabled style={{ width: 300 }} />
      </FormRow>
    </SimpleForm>
  </Edit>
);

