import { AutocompleteRefInput } from '@components/autocomplete-ref-input.tsx';
import { AutocompleteSelectInput } from '@core/components';
import { adminEndpointsEnum, resourceEnum } from '@core/enums';
import { DeleteFeatureFlagButton } from '@resources/featureFlag/components/delete-feature-flag-button.tsx';
import {
  BooleanField,
  Datagrid,
  List,
  RaRecord,
  ResourceContextProvider,
  SearchInput, SelectArrayInput,
  TextField,
  useRecordContext,
  WrapperField,
} from 'react-admin';

const choices = [
  { id: 'true', name: 'true' },
  { id: 'false', name: 'false' },
];

const reviewFilters = [
  <SearchInput id="q" name="q" source="q" alwaysOn />,
  <SelectArrayInput
    name="value"
    source="value"
    choices={choices}
    alwaysOn
  />,
  <AutocompleteSelectInput
    name="name"
    source="name"
    patch={adminEndpointsEnum.featureFlagNames}
    alwaysOn
    style={{ minWidth: 300 }}
  />,
  <AutocompleteRefInput
    name="providerId"
    source="providerId"
    label="Provider"
    reference={resourceEnum.provider}
    style={{ width: 300 }}
    optionValue="id"
    optionText="shortName"
    alwaysOn
  />,
  <AutocompleteRefInput
    name="companyId"
    source="Company"
    label="Company"
    reference={resourceEnum.company}
    filterToQuery = {(searchText:string) => ({ q: `${searchText}`, })}
    refFilter={{type:'regular'}}
    style={{ width: 1000 }}
    optionValue="id"
    optionText="companyName"
    alwaysOn
  />
];

const ConditionalButtons = () => {
  const record: any = useRecordContext();
  const getButtons = () => {
    return (
      <>
        <DeleteFeatureFlagButton
          record={record}
        />
      </>
    );
  };
  return <>{getButtons()}</>;
};

export const FeatureFlagList = (props: RaRecord) => {
  return (
    <ResourceContextProvider value={resourceEnum.featureFlag}>
      <List
        {...props}
        empty={false}
        hasCreate={true}
        filters={reviewFilters}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="name" />
          <BooleanField source="value" />
          <WrapperField label="Company" sortBy="companyId">
            <TextField source="company.name" />
          </WrapperField>
          <WrapperField label="Provider" sortBy="providerId">
            <TextField source="provider.name" />
          </WrapperField>
          <ConditionalButtons />
          <br />
        </Datagrid>
      </List>
    </ResourceContextProvider>
  );
};
