import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { sanitizeFieldRestProps, TextFieldProps, useRecordContext } from 'react-admin';

export const TruncatedTextField = (
  props: TextFieldProps & {
    maxLength: number
  },
) => {
  const { source, maxLength } = props;
  const record = useRecordContext(props);
  const text = record[source] || '';
  const truncatedText = text.length > maxLength ? `${text.substr(0, maxLength || 50)}...` : text;

  return (
    <Tooltip title={text}>
      <Typography
        component="span"
        variant="body2"
        className={props.className}
        {...sanitizeFieldRestProps(props)}
      >
        {truncatedText}
      </Typography>
    </Tooltip>
  );
};
