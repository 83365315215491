export enum resourceEnum  {
    company= 'company',
    user= 'user',
    rapc= 'rapc',
    // admin section
    sma= 'sma',
    template= 'template',
    featureFlag= 'feature-flag',
    provider= 'provider',
    analytic= 'analytic',
}

export enum adminEndpointsEnum {
    templateCompanyReference= 'template/company-reference',
    featureFlagNames= 'feature-flag/names',
}

export enum dictionaryEndpointsEnum {
    countries = 'countries',
    jurisdictionCodes = 'jurisdiction-codes',
}

