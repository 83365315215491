import {  optionsUrl, resourceEnum } from '@core/enums';
import { fetchClient } from '@core/utils';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import {
    useNotify,
    useRedirect,
    Button,
    useRefresh,
    ButtonProps,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';

export const DeleteFeatureFlagButton = (props: ButtonProps & {record:{
    id:number,
    companyId:number,
    providerId:number,
    name:string,
    }}) => {
    const resource = resourceEnum.featureFlag;
    const [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const isDefaultRow = props.record.companyId || props.record.providerId

    const handleClick = async () => {
        setLoading(true);
        let url =`${optionsUrl.proxyAdminUrl}/${resourceEnum.featureFlag}/${props.record.id}`;
        if(!isDefaultRow){
            url += '?isForce=true'
        }
        fetchClient(url, {
            method: 'DELETE',
        }).then(response => {
            if (response.status === 200) {
                notify('FeatureFlag Deleted!', {type: 'success'});
                redirect(`/${resource}`);
                refresh();
                return;
            }
            response.json().then(data => {
                if (data.status === 'error') {
                    notify(`${data.message}`, {type: 'warning'});
                    redirect(`/${resource}`);
                    return;
                }
            });
        }).catch(() => {
            notify('Error: FeatureFlag failed', {type: 'warning'});
            redirect(`/${resource}`);
            return;
        }).finally(() => {
            setLoading(false);
        });
        notify(`${resource} Deleted!`, { type: 'success' });
        redirect(`/${resource}`);
        refresh();
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure to delete current FeatureFlag ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleClick();
                    },
                },
                {
                    label: 'No',
                    onClick: () => redirect(`/${resource}`),
                },
            ],
            childrenElement: () => {
                return isDefaultRow ? null : (
                  <Typography variant="body2" color="error">
                      Pay attention this is default value of {props.record.name}
                  </Typography>
                );
            },
        });
    };

    return (
        <Button
            {...props}
            label={'Delete'}
            onClick={submit}
            disabled={loading && props.disabled}
        >
            <DeleteIcon
              color={!isDefaultRow?'warning':'primary'}
            />
        </Button>
    );
};
