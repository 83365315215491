import {optionsUrl} from '@core/enums';
import {fetchClient} from '@core/utils';
import {CorporateMailValidator} from '@core/validators/corporate-mail-validator.ts';
import {Box, Typography} from '@mui/material';
import {email, required, SimpleForm, TextInput, useNotify, useRedirect} from 'react-admin';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isLowerCaseEmailValidator } from '@core/validators';

export const SMAInvitation = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const handleClick = async (data) => {
    fetchClient(optionsUrl.proxyAdminUrl + `/sma/invite`, {
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
      }),
    }, notify).then(data => {
      data.json().then(function(data) {
        if (data.status === 'error') {
          redirect('/sma-admin/invitation');
          notify(`${data.message}`, {type: 'warning'});
        } else {
          redirect(
              '/sma-admin/invitation/confirmation',
              null,
              null,
              {},
              {data},
          );
          notify('Admin successfully invited!', {
            type: 'success',
          });
        }
      }).catch(function(e) {
        notify(e);
        redirect('sma-admin/invitation');
      });
    }).catch(() => {
      notify('Error: user suspension failed', {type: 'warning'});
    }).finally(() => {
    });
  };

  const handleSubmit = event => {
    confirmAlert({
      title: 'Confirm to Invite Secro Main Admin',
      message: `Are you sure to invite current admin  with mail ${event.email} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClick(event),
        },
        {
          label: 'No',
          onClick: () => redirect(''),
        },
      ],
    });
  };

  return (
      <Box display="flex" my={2}>
        <Box flex={1}>
          <SimpleForm onSubmit={handleSubmit} sx={{maxWidth: 500}}>
            <Typography variant="h6" gutterBottom>
              Invite Secro main Admin
            </Typography>
            <TextInput
                name="email"
                validate={[required(), email(), CorporateMailValidator, isLowerCaseEmailValidator]}
                source="email"
                fullWidth
            />
          </SimpleForm>
        </Box>
      </Box>
  );
};
