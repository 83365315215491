import {resourceEnum, userStatusEnum} from '@core/enums';
import {
  DateInput,
  Filter,
  List,
  Pagination,
  ResourceContextProvider,
  SelectInput,
  TextInput,
  useListContext,
} from 'react-admin';
import {UserListActions} from './components/user-list.action.tsx';
import {UserListTabbedDatagrid} from './custom-pages/user-list-tabbed-datagrid.tsx';

const UserPagination = () => <Pagination rowsPerPageOptions={[10, 50, 100]}/>;

const userStatusChoices = [
  {name: 'Active', id: 'active'},
  {name: 'Invited', id: 'invited'},
  {name: 'Suspended', id: 'suspended'},
  {name: 'Deactivated', id: 'deactivated'},
];

const userRoleChoices = [
  {name: 'CompanyOfficer', id: 'companyOfficer'},
  {name: 'ContractManager', id: 'contractManager'},
  {name: 'ExternalUser', id: 'externalUser'},
];

const userOnboardingStatusChoices = [
  {name: 'TempPassword', id: 'tempPassword'},
  {name: 'UserProfile', id: 'userProfile'},
  {name: 'TwoFA', id: 'twoFA'},
  {name: 'KycCheck', id: 'kycCheck'},
  {name: 'AmlBusinessCheck', id: 'amlBusinessCheck'},
];

const UserFilter = props => {
  const context = useListContext();
  let filteredUserStatusChoices = [...userStatusChoices];
  if (
      context.filterValues &&
      context.filterValues.status !== userStatusEnum.Deactivated
  ) {
    filteredUserStatusChoices = filteredUserStatusChoices.filter(
        item => item.id !== userStatusEnum.Deactivated,
    );
  }

  return (
      <Filter {...props}>
        <TextInput name='q' label="Search" source="q" alwaysOn/>
        <DateInput name='createdFrom' source="createdFrom" label="Created From" alwaysOn/>
        <DateInput name='createdTill' source="createdTill" label="Created Till" alwaysOn/>
        {context.filterValues.status !== userStatusEnum.Deactivated && (
            <SelectInput
                name="status"
                source="status"
                choices={filteredUserStatusChoices}
                alwaysOn
                disabled={
                    context.filterValues &&
                    context.filterValues.status ===
                    userStatusEnum.Deactivated
                }
            />
        )}
        <SelectInput name="role" source="role" choices={userRoleChoices} alwaysOn/>
        <SelectInput
            name="onboardingStatus"
            source="onboardingStatus"
            choices={userOnboardingStatusChoices}
            alwaysOn
            fullWidth
            style={{width: 200}}
        />
      </Filter>
  );
};

export const UserList = props => {
  return (
      <ResourceContextProvider value={resourceEnum.user}>
        <List
            empty={false}
            {...props}
            filters={<UserFilter/>}
            pagination={<UserPagination/>}
            actions={<UserListActions/>}
            sort={{ field: 'id', order: 'DESC' }}
        >
          <UserListTabbedDatagrid/>
        </List>
      </ResourceContextProvider>
  );
};
