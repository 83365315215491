import {
    useNotify,
    useRedirect,
    Button,
    useRecordContext,
    useRefresh,
    useDelete,
    ButtonProps,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';

export const DeleteRowButton = (props: ButtonProps & { resource:string }) => {
    const record = useRecordContext();
    const resource = props.resource;

    const [deleteOne, { isLoading, error }] = useDelete(undefined, undefined, {
        onError: error => {
            notify(`${error}`, { type: 'warning' });
        },
    });
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        deleteOne(resource, { id: record.id, previousData: record });
        if (error) {
            notify(`Error: ${resource} deletion failed`, {type:'warning'});
            return redirect(`/${resource}`);
        }
        notify(`${resource} Deleted!`, { type: 'success' });
        redirect(`/${resource}`);
        refresh();
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: `Are you sure to delete current ${resource} ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(),
                },
                {
                    label: 'No',
                    onClick: () => redirect(`/${resource}`),
                },
            ],
        });
    };

    return (
        <Button
            {...props}
            label={'Delete'}
            onClick={submit}
            disabled={isLoading && props.disabled}
            color="secondary"
        >
            <DeleteIcon />
        </Button>
    );
};
