import { companyStatusEnum, optionsUrl, userStatusEnum } from '@core/enums';
import { fetchClient } from '@core/utils';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { useState } from 'react';
import { Button, useNotify, useRecordContext, useRedirect, useRefresh } from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const RestoreAccessUserButton = ({ disabled, name }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  let deactivate: boolean;
  if (disabled) {
    deactivate = true;
  } else {
    deactivate = loading;
  }

  const url =
    record.status === companyStatusEnum.Deactivated
      ? `/user/${record.id}/restore-deactivated`
      : `/user/${record.id}/restore-access`;

  const handleClick = async () => {
    setLoading(true);
    fetchClient(optionsUrl.proxyAdminUrl + url, {
      method: 'POST',
    }, notify).then(data => {
      data.json().then(function(data) {
        if (data.status === 'error') {
          notify(`${data.message}`, { type: 'warning' });
          redirect('/user');
        } else if (
          record.status === userStatusEnum.Deactivated
        ) {
          redirect(
            '/rapc-admin/invitation/confirmation',
            null,
            null,
            {},
            { data },
          );
          notify('User Access Restored!', {
            type: 'success',
          });
        } else {
          redirect('/user');
          notify('User Access Restored!', {
            type: 'success',
          });
          refresh();
        }
      }).catch(function(e) {
        notify(e);
        redirect('/user');
      });
    }).catch(() => {
      deactivate = false;
      notify('Error: user restore access failed', { type: 'warning' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to restore access',
      message: 'Are you sure to restore access of current user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClick(),
        },
        {
          label: 'No',
          onClick: () => redirect('/user'),
        },
      ],
    });
  };
  return (
    <Button
      label={name}
      onClick={submit}
      disabled={deactivate}
    >
      <SwitchAccessShortcutIcon />
    </Button>
  );
};
