import { AutocompleteRefInput } from '@components/autocomplete-ref-input.tsx';
import { AutocompleteSelectInput } from '@core/components';
import { adminEndpointsEnum, resourceEnum } from '@core/enums';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import {
  BooleanInput,
  Button,
  Create,
  CreateProps,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRedirect,
  useRefresh,
} from 'react-admin';

const FeatureFlagCreateToolbar = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const mutationOptions = {
    onSuccess: () => {
      redirect(`/${resourceEnum.featureFlag}?order=DESC&page=1&perPage=10&sort=id`);
      refresh();
    },
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        type="button"
        mutationOptions={mutationOptions}
      />
      <div style={{ margin: '1em' }}>
        <Button
          label={'Cancel'}
          onClick={() => {
            redirect(`/${resourceEnum.featureFlag}`);
            refresh();
          }}
          variant="contained"
          size="medium"
        >
          <CancelIcon />
        </Button>
      </div>
    </Toolbar>
  );
};

const validateUserCreation = (values: any) => {
  const errors: { [key: string]: string } = {};
  if (!values.name) {
    errors['name'] = 'The name is required';
  }
  if (!values.companyId && !values.providerId) {
    errors['companyId'] = 'You must select either a Company or a Provider.';
    errors['providerId'] = 'You must select either a Company or a Provider.';
  }
  return errors;
};

export const FeatureFlagCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validateUserCreation} toolbar={<FeatureFlagCreateToolbar />}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="space-between"
        >
          <AutocompleteSelectInput
            name="name"
            source="name"
            patch={adminEndpointsEnum.featureFlagNames}
            style={{ width: 600 }}
            validate={[required()]}
          />
          <BooleanInput name="value" source="value" />
          <AutocompleteRefInput
            name="companyId"
            source="company"
            label="Company"
            reference={resourceEnum.company}
            filterToQuery = {(searchText:string) => ({ q: `${searchText}`, })}
            refFilter={{type:'regular',status: 'trusted'}}
            style={{ width: 600 }}
            optionValue="id"
            optionText="companyName"
          />
          <AutocompleteRefInput
            name="providerId"
            source="providerId"
            label="Provider"
            reference={resourceEnum.provider}
            style={{ width: 400 }}
            optionValue="id"
            optionText="shortName"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
