import { AutocompleteRefInput } from '@components/autocomplete-ref-input.tsx';
import { resourceEnum } from '@core/enums';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import {
  BooleanInput,
  Button,
  Edit, SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
  useRefresh,
} from 'react-admin';

const FeatureFlagToolbar = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
    <Toolbar {...props}>
      <SaveButton
        type="button"
      />
      <div style={{ margin: '1em' }}>
        <Button
          label={'Cancel'}
          onClick={() => {
            redirect(`/${resourceEnum.featureFlag}`);
            refresh();
          }}
          variant="contained"
          size="medium"
        >
          <CancelIcon />
        </Button>
      </div>
    </Toolbar>
  );
};

export const FeatureFlagEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<FeatureFlagToolbar />}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="space-between"
      >
        <TextInput name="id" source="id" disabled style={{ width: 100 }} />
        <TextInput
          name="name"
          source="name"
          style={{ width: 600 }}
          disabled={true}
        />
        <BooleanInput name="value" source="value" />

        <AutocompleteRefInput
          name="companyId"
          source="companyId"
          label="Company"
          reference={resourceEnum.company}
          filterToQuery = {(searchText:string) => ({ q: `${searchText}`, })}
          refFilter={{type:'regular',status: 'trusted'}}
          style={{ width: 600 }}
          optionValue="id"
          optionText="companyName"
          disabled={true}
        />

        <AutocompleteRefInput
          name="providerId"
          source="providerId"
          label="Provider"
          reference={resourceEnum.provider}
          style={{ width: 400 }}
          optionValue="id"
          optionText="shortName"
          disabled={true}
        />
      </Box>
    </SimpleForm>
  </Edit>
);

