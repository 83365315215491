import { AutocompleteRefInput } from '@core/components';
import { clmProviderChoices, resourceEnum } from '@core/enums';
import { ActivateTemplateButton } from '@resources/template/components/activate-template-button.tsx';
import { DeactivateTemplateButton } from '@resources/template/components/deactivate-template-button.tsx';
import { TemplateListActions } from '@resources/template/components/template-list.action.tsx';
import {
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ResourceContextProvider,
  SearchInput,
  SelectArrayInput,
  TextField,
  useRecordContext,
  WrapperField,
} from 'react-admin';

const templateFilter = [
  <BooleanInput
    label="Only Private"
    name="isPrivate"
    source="isPrivate"
    alwaysOn
    size='medium'
  />,
  <SearchInput id="q" name="q" source="q" alwaysOn />,
  <SelectArrayInput
    label="Clm-Provider"
    name="clmProvider"
    source="clmProvider"
    style={{ width: 200 }}
    choices={clmProviderChoices.map(el=> ({
      ...el,
      disabled:false
    }))}
    alwaysOn
  />,
  <SelectArrayInput
    label="Status"
    name="status"
    source="status"
    choices={[
      { name: 'Draft', id: 'Draft' },
      { name: 'Published', id: 'Published' },
      { name: 'Deactivated', id: 'Deactivated' },
    ]}
    alwaysOn
    fullWidth
    style={{ width: 300 }}
  />,
  <AutocompleteRefInput
    name="providerId"
    source="providerId"
    label="Provider"
    reference={resourceEnum.provider}
    style={{ width: 300 }}
    optionValue="id"
    optionText="shortName"
    alwaysOn
  />,
];

const ConditionalButtons = () => {
  const record = useRecordContext();
  switch (record.status) {
    case 'draft':
    case 'deactivated':
      return (
        <ActivateTemplateButton disabled={false} name={'Activate'} />
      );
    case 'published':
      return (
        <DeactivateTemplateButton disabled={false} name={'Deactivate'} />
      );
  }
  return null;
};

export const TemplateList = props => {
  return (
    <ResourceContextProvider value={resourceEnum.template}>
      <List
        {...props}
        empty={false}
        hasCreate={false}
        actions={<TemplateListActions />}
        filters={templateFilter}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="shortName" />
          <TextField source="clmProvider" />
          <ChipField source="status" />
          <FunctionField
            label="is private"
            render={(v) => v?.companies?.length ? 'yes' : ''}
          />
          <WrapperField label="Provider" sortBy="providerId">
            <TextField source="provider.name" />
          </WrapperField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ConditionalButtons />
          <br />
        </Datagrid>
      </List>
    </ResourceContextProvider>
  );
};
