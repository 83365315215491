import { AutocompleteRefInput } from '@components/autocomplete-ref-input.tsx';
import { FormRow } from '@components/form-row.tsx';
import { PhoneNumberInput } from '@components/phone-number-input.tsx';
import { UserRoleSelectInput } from '@components/user-role-select-input.tsx';
import { optionsUrl, resourceEnum } from '@core/enums';
import { fetchClient } from '@core/utils';
import { CorporateMailValidator } from '@core/validators/corporate-mail-validator.ts';
import { HumanNameValidator } from '@core/validators/human-name-validator.ts';
import CancelIcon from '@mui/icons-material/Cancel';
import { Card, CardContent, Typography } from '@mui/material';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Button,
  email,
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { isLowerCaseEmailValidator } from '@core/validators';

export const UserInvitation = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const handleClick = async data => {

    const body = JSON.stringify({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber || null,
      role: data.role,
      companyId: data.companyId,
    })

    fetchClient(optionsUrl.proxyAdminUrl + `/user`, {
      method: 'POST',
      body,
    }, notify).then(data => {
      data.json().then(function(data) {
        // [TODO] need to be correct in another way like
        // return error with shortcode on backend side
        if (data.status === 'error') {
          redirect('/user-custom/invitation-user');
          notify(`${data.message}`, { type: 'warning' });
        } else {
          redirect(
            '/user-custom/invitation/confirmation',
            null,
            null,
            {},
            { data: data },
          );
          notify('User successfully invited!', {
            type: 'success',
          });
        }
      }).catch(function(e) {
        notify(e);
        redirect('/user-custom/invitation-user');
      });
    }).catch(() => {
      notify('Error: user suspension failed');
    });
  };

  const transformUser = (data) => ({
    ...data,
    phoneNumber: data.phoneNumber || null,
  });

  const UserCreateToolbar = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    return (
      <Toolbar {...props}>
        <SaveButton transform={transformUser} />
        <div style={{ margin: '1em' }}>
          <Button
            label={'Cancel'}
            onClick={() => {
              redirect(`/user`);
              refresh();
            }}
            variant="contained"
            size="medium"
          >
            <CancelIcon />
          </Button>
        </div>
      </Toolbar>
    );
  };

  return (
    <Card style={{ margin: '1em' }}>
      <CardContent>
        <SimpleForm onSubmit={handleClick} toolbar={<UserCreateToolbar />}>
          <Typography variant="h6" gutterBottom>
            Invite User:
          </Typography>
          <FormRow width="800px">
            <TextInput
              name="firstName"
              source="firstName"
              style={{ width: 400 }}
              validate={[
                required(),
                minLength(3),
                maxLength(30),
                HumanNameValidator,
              ]}
            />
            <TextInput
              name="lastName"
              source="lastName"
              style={{ width: 400 }}
              validate={[
                required(),
                minLength(3),
                maxLength(30),
                HumanNameValidator,
              ]}
            />
          </FormRow>
          <TextInput
            name="email"
            source="email"
            style={{ width: 500 }}
            validate={[required(), email(), CorporateMailValidator, isLowerCaseEmailValidator]}
          />
          <PhoneNumberInput fullWidth source="phoneNumber" style={{ width: 500 }} />
          <br />
          <AutocompleteRefInput
            name="companyId"
            source="Company"
            label="Company"
            reference={resourceEnum.company}
            filterToQuery = {(searchText:string) => ({ q: `${searchText}`, })}
            refFilter={{type:'regular',status: 'trusted'}}
            style={{ width: 800 }}
            optionValue="id"
            optionText="companyName"
          />
          <UserRoleSelectInput
            name="role"
            source="role"
            style={{ width: 300 }}
            validate={required()}
          />
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

