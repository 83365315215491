import { optionsUrl } from '@core/enums';
import { httpHeader } from '@core/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useNotify, useRedirect } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object({
  password: yup.string().required('Password is required'),
}).required();

const theme = createTheme({
  palette: {
    primary: {
      main: '#026062',
    },
  },
});

export const Registration = () => {
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const redirect = useRedirect();
  const notify = useNotify();

  const onSubmit = async (data) => {
    try {
      const response = await fetch(optionsUrl.proxyAdminUrl + `/sma/auth`, {
        method: 'POST',
        headers: httpHeader(),
        body: JSON.stringify({
          invitationToken: token,
          password: data.password,
        }),
      });
      const result = await response.json();
      if (result?.token) {
        localStorage.setItem('auth', JSON.stringify({ token: result.token }));
        redirect('/setup', null, null, {}, { data: result });
        return
      }
      redirect(`/registration/${token}`);
      notify('Invalid password!', { type: 'error' });
    } catch (error) {
      notify('Error: user registration failed', { type: 'warning' });
      redirect(`/registration/${token}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img width={56} src="../../public/brand-logo.png" alt="Semy Sharp" />
          <Typography component="h1" variant="h5">
            Welcome to Secro
          </Typography>
          <Typography component="h1" variant="h6">
            Please insert your password
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  {...register('password')}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  fullWidth
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : ''}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 4, mb: 6 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
