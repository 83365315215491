import {optionsUrl} from '@core/enums';
import {fetchClient} from '@core/utils';
import BlockIcon from '@mui/icons-material/Block';
import {useState} from 'react';
import {Button, useNotify, useRecordContext, useRedirect, useRefresh} from 'react-admin';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const DeleteUserButton = ({disabled, name}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  let deactivate: boolean;
  if (disabled) {
    deactivate = true;
  } else {
    deactivate = loading;
  }

  const handleClick = async () => {
    setLoading(true);
    fetchClient(optionsUrl.proxyAdminUrl + `/user/${record.id}/deactivate`, {
      method: 'POST',
    }, notify).then(data => {
      data.json().then(function(data) {
        if (data.statusCode === 500) {
          notify(`${data.message}`, {type: 'warning'});
        }
        if (data.status === 'error') {
          notify(`${data.message}`, {type: 'warning'});
        }
        notify('User deleted!', {type: 'success'});
        redirect('/user');
        refresh();
      }).catch(function(e) {
        notify(e);
        redirect('/user');
      });
    }).catch(() => {
      deactivate = false;
      notify('Error: user deletion failed', {type: 'warning'});
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete current user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClick(),
        },
        {
          label: 'No',
          onClick: () => redirect('/user'),
        },
      ],
    });
  };
  return (
      <Button
          label={name}
          onClick={submit}
          disabled={deactivate}
      >
        <BlockIcon/>
      </Button>
  );
};
