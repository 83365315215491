import { optionsUrl } from '@core/enums';
import { fetchClient } from '@core/utils';
import { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AutocompleteInput, AutocompleteInputProps } from 'react-admin';

interface IRows {
  id: string;
}

interface IDataResponse {
  items: IRows[];
  paging: { total: number };
}

interface IDataResponse {
  JurisdictionCodes: IRows[];
  paging: { total: number };
}

/**
 * Deprecated pls use AutocompleteSelectInput instead
 * todo because of payload?.items & JurisdictionCodes - fix it on back later
 * @param props
 * @constructor
 */
export const AutocompleteDictionaryInput = (props: AutocompleteInputProps & {
  patch: string,
}) => {
  const [rows, setRows] = useState<IRows[]>([]);
  const optionValue = props.optionValue || 'id';
  const optionText = props.optionText || 'name';

  const fetchData = async (url: string) => {
    try {
      const response = await fetchClient(url, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }
      const data: IRows[] | IDataResponse = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error.message);
      return [];
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      const payload = await fetchData(`${optionsUrl.proxyDictionaryUrl}/${props.patch}`);
      if (Array.isArray(payload)) {
        setRows(payload);
      } else if (payload?.items?.length) {
        setRows(payload.items);
      } else if (payload?.JurisdictionCodes?.length) {
        setRows(payload.JurisdictionCodes);
      } else {
        setRows([]);
      }
    };
    fetchDataAsync();
  }, [props.patch, props.filter]);

  return (
    <AutocompleteInput
      {...props}
      optionValue={optionValue}
      optionText={optionText}
      choices={rows}
    />
  );
};
