import './App.css';
import {resourceEnum, userRoleEnum} from '@core/enums';
import {Layout} from '@core/layout';
import {authProvider, dataProvider} from '@core/providers';
import {lightTheme} from '@core/themes/light-theme.tsx';
import { AnalyticList } from '@resources/analytic/analytic-list.tsx';
import {CompanyEdit} from '@resources/company/company-edit.tsx';
import {CompanyList} from '@resources/company/company-list.tsx';
import {RapcInvitationBusiness} from '@resources/company/custom-pages/invitation-business.tsx';
import {RAPCInvitationCompany} from '@resources/company/custom-pages/invitation-company.tsx';
import {RAPCInvitationConfirmation} from '@resources/company/custom-pages/invitation-confirmation.tsx';
import { FeatureFlagCreate } from '@resources/featureFlag/feature-flag-create.tsx';
import { FeatureFlagEdit } from '@resources/featureFlag/feature-flag-edit.tsx';
import { FeatureFlagList } from '@resources/featureFlag/feature-flag-list.tsx';
import {RapcEdit} from '@resources/rapc/rapc-edit.tsx';
import {RapcList} from '@resources/rapc/rapc-list.tsx';
import {SMAInvitationConfirmation} from '@resources/sma/custom-pages/invitation-confirmation.tsx';
import {SMAInvitation} from '@resources/sma/custom-pages/invitation.tsx';
import {SmaEdit} from '@resources/sma/sma-edit.tsx';
import {SmaList} from '@resources/sma/sma-list.tsx';
import {TemplateEdit} from '@resources/template/template-edit.tsx';
import {TemplateList} from '@resources/template/template-list.tsx';
import {UserInvitationConfirmation} from '@resources/user/custom-pages/invitation-confirmation.tsx';
import {UserInvitation} from '@resources/user/custom-pages/invitation-user.tsx';
import {UserEdit} from '@resources/user/user-edit.tsx';
import {UserList} from '@resources/user/user-list.tsx';
import {Admin as RAAdmin, Authenticated, CustomRoutes, Resource} from 'react-admin';
import {Route} from 'react-router-dom';
import {Registration} from './custom-pages/registration.tsx';
import {Setup} from './custom-pages/setup.tsx';
import { MyLoginPage } from './custom-pages/my-login-page.tsx';



const App = () => (
    <RAAdmin
        title="My Secro Admin Dashboard"
        theme={lightTheme}
        layout={Layout}
        loginPage={MyLoginPage}
        authProvider={authProvider}
        dataProvider={dataProvider}
    >
      {(permissions) => (
          <>
            <Resource
                options={{label: 'Companies'}}
                name={resourceEnum.company}
                list={CompanyList}
                edit={CompanyEdit}
            />
            <Resource
                options={{label: 'Users'}}
                name={resourceEnum.user}
                list={UserList}
                edit={UserEdit}
            />
            <Resource
                options={{label: 'Rapc'}}
                name={resourceEnum.rapc}
                list={RapcList}
                edit={RapcEdit}
            />
            {permissions === userRoleEnum.SuperAdmin
                && (
                    <>
                        <Resource
                          options={{label: 'Analytic'}}
                          name={resourceEnum.analytic}
                          list={AnalyticList}
                        />
                      <Resource
                          options={{label: 'Secro Main Admin (SMA)'}}
                          name={resourceEnum.sma}
                          list={SmaList}
                          edit={SmaEdit}
                      />

                      <Resource
                          options={{label: 'Templates'}}
                          name={resourceEnum.template}
                          list={TemplateList}
                          edit={TemplateEdit}
                      />

                      <Resource
                          options={{label: 'FeatureFlag'}}
                          name={resourceEnum.featureFlag}
                          list={FeatureFlagList}
                          edit={FeatureFlagEdit}
                          create={FeatureFlagCreate}
                      />
                    </>
                )
            }
            <CustomRoutes>
              <Route path="/sma-admin/">
                <Route
                    path="invitation"
                    element={
                      <Authenticated>
                        <SMAInvitation/>
                      </Authenticated>
                    }
                />
                <Route
                    path="invitation/confirmation"
                    element={
                      <Authenticated>
                        <SMAInvitationConfirmation/>
                      </Authenticated>
                    }
                />
              </Route>
              <Route path="/rapc-admin/">
                <Route
                    path="invitation-company"
                    element={
                      <Authenticated>
                        <RAPCInvitationCompany/>
                      </Authenticated>
                    }
                />
                <Route
                    path="invitation-business"
                    element={
                      <Authenticated>
                        <RapcInvitationBusiness/>
                      </Authenticated>
                    }
                />
                <Route
                    path="invitation/confirmation"
                    element={
                      <Authenticated>
                        <RAPCInvitationConfirmation/>
                      </Authenticated>
                    }
                />
              </Route>
              <Route path="/user-custom/">
                <Route
                    path="invitation-user"
                    element={
                      <Authenticated>
                        <UserInvitation/>
                      </Authenticated>
                    }
                />
                <Route
                    path="invitation/confirmation"
                    element={
                      <Authenticated>
                        <UserInvitationConfirmation/>
                      </Authenticated>
                    }
                />
              </Route>
            </CustomRoutes>
            <CustomRoutes noLayout>
              <Route path="/registration/:token" element={<Registration/>}/>
              <Route
                  path="/setup"
                  element={
                    <Authenticated>
                      <Setup/>
                    </Authenticated>
                  }
              />
            </CustomRoutes>
          </>
      )}

    </RAAdmin>
);

export default App;
