import {resourceEnum, userStatusEnum} from '@core/enums';
import {
  DateInput,
  Filter,
  List,
  Pagination,
  ResourceContextProvider,
  SelectInput,
  TextInput,
  useListContext,
} from 'react-admin';
import {RapcListTabbedDatagrid} from './custom-pages/rapc-list-tabbed-datagrid.tsx';

const RapcPagination = () => <Pagination rowsPerPageOptions={[10, 50, 100]}/>;

const userStatusChoices = [
  {name: 'Active', id: 'active'},
  {name: 'Invited', id: 'invited'},
  {name: 'Suspended', id: 'suspended'},
  {name: 'Deactivated', id: 'deactivated'},
];

const rapcOnboardingStatusChoices = [
  {name: 'TempPassword', id: 'tempPassword'},
  {name: 'UserProfile', id: 'userProfile'},
  {name: 'TwoFA', id: 'twoFA'},
];

const RapcFilter = props => {
  const context = useListContext();
  let filteredUserStatusChoices = [...userStatusChoices];
  if (
      context.filterValues &&
      context.filterValues.status !== userStatusEnum.Deactivated
  ) {
    filteredUserStatusChoices = filteredUserStatusChoices.filter(
        item => item.id !== userStatusEnum.Deactivated,
    );
  }
  if (context?.filterValues?.status === userStatusEnum.Deactivated) {
    return (
        <Filter {...props}>
          <TextInput name="q" label="Search" source="q" alwaysOn/>
          <DateInput
              name="deactivatedFrom"
              source="deactivatedFrom"
              label="Deactivated From"
              alwaysOn
          />
          <DateInput
              name="deactivatedTill"
              source="deactivatedTill"
              label="Deactivated Till"
              alwaysOn
          />
          <DateInput name="createdFrom" source="createdFrom" label="Created From" alwaysOn/>
          <DateInput name="createdTill" source="createdTill" label="Created Till" alwaysOn/>
          <SelectInput
              label="Onboarding Status"
              name="onboardingStatus"
              source="onboardingStatus"
              choices={rapcOnboardingStatusChoices}
              alwaysOn
              fullWidth
              style={{width: 200}}
          />
        </Filter>
    );
  }
  return (
      <Filter {...props}>
        <TextInput name="q" label="Search" source="q" alwaysOn/>
        <DateInput name="createdFrom" source="createdFrom" label="Created From" alwaysOn/>
        <DateInput name="createdTill" source="createdTill" label="Created Till" alwaysOn/>
        <SelectInput
            name="status"
            source="status"
            choices={filteredUserStatusChoices}
            alwaysOn
            disabled={
                context.filterValues &&
                context.filterValues.status === userStatusEnum.Deactivated
            }
        />
        <SelectInput
            label="Onboarding Status"
            name="onboardingStatus"
            source="onboardingStatus"
            choices={rapcOnboardingStatusChoices}
            alwaysOn
            fullWidth
            style={{width: 200, marginTop:'1px'}}
        />
      </Filter>
  );
};

export const RapcList = props => {
  return (
      <ResourceContextProvider value={resourceEnum.rapc}>
        <List
            {...props}
            empty={false}
            filters={<RapcFilter/>}
            pagination={<RapcPagination/>}
            sort={{ field: 'id', order: 'DESC' }}
        >
          <RapcListTabbedDatagrid/>
        </List>
      </ResourceContextProvider>
  );
};
