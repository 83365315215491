import {userOnboardingStatusEnum, userVerificationStatusEnum, userVerificationTypeEnum} from '@core/enums';
import {HumanNameValidator} from '@core/validators/human-name-validator.ts';
import { FormRow } from '@components/form-row.tsx';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  DateInput,
  Edit,
  email,
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import {PhoneNumberInput} from '@components/phone-number-input.tsx';

const transformRapc = data => ({
  ...data,
  phoneNumber: data.phoneNumber || null,
});

const RapcEditToolbar = props => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
      <Toolbar {...props}>
        <SaveButton type="button" transform={transformRapc}/>
        <div style={{margin: '1em'}}>
          <Button
              label={'Cancel'}
              onClick={() => {
                redirect(`/rapc`);
                refresh();
              }}
              variant="contained"
              size="medium"
          >
            <CancelIcon/>
          </Button>
        </div>
      </Toolbar>
  );
};

const RapcEditForm = () => {
  const record = useRecordContext();

  const isKycPassed = record.userVerification?.some(
      item =>
          item.verificationType === userVerificationTypeEnum.KYC &&
          item.verificationStatus ===
          userVerificationStatusEnum.VerificationPassed,
  );

  const isOtpPassed = ![
    userOnboardingStatusEnum.companyProfile,
    userOnboardingStatusEnum.tempPassword,
    userOnboardingStatusEnum.userProfile,
    userOnboardingStatusEnum.twoFA,
  ].includes(record.onboardingStatus);

  return (
      <div>
        <SimpleForm toolbar={<RapcEditToolbar/>}>
          <TextInput name="id" source="id" disabled style={{ width: '200px' }} />
          <FormRow width="600px">
            <TextInput
              name="firstName"
              source="firstName"
              disabled={isKycPassed}
              style={{ width: 300 }}
              validate={[
                required(),
                minLength(3),
                maxLength(30),
                HumanNameValidator,
              ]}
            />
            <TextInput
              name="lastName"
              source="lastName"
              disabled={isKycPassed}
              style={{ width: 300 }}
              validate={[
                required(),
                minLength(3),
                maxLength(30),
                HumanNameValidator,
              ]}
            />
          </FormRow>
          <FormRow width="600px">
          <PhoneNumberInput
              disabled={isOtpPassed}
              source="phoneNumber"
              style={{ width: 300 }}
          />
          <TextInput
              name="email"
              source="email"
              style={{ width: 300 }}
              disabled
              validate={[required(), email()]}
          />
          </FormRow>
          <TextInput name="status" source="status" disabled/>
          <DateInput name="createdAt" source="createdAt" disabled/>
          <FormRow width="600px">
            <TextInput name="role" source="role" disabled style={{ width: 300 }} />
            <TextInput name="company" source="company" disabled style={{ width: 300 }} />
          </FormRow>
        </SimpleForm>
      </div>
  );
};

export const RapcEdit = (props) => (
    <Edit {...props}>
      <RapcEditForm {...props} />
    </Edit>
);

