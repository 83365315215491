export enum clmProviderEnum {
  Absent = 'absent',
  Legito = 'legito',
  Secro = 'secro',
  PdfForms = 'pdf-forms',
}

export const clmProviderChoices = [
  {
    name: 'Secro',
    id: clmProviderEnum.Secro,
  },
  {
    name: 'PdfForms',
    id: clmProviderEnum.PdfForms,
  },
  {
    name: 'Absent',
    id: clmProviderEnum.Absent,
    disabled: true,
  },
  {
    name: 'Legito',
    id: clmProviderEnum.Legito,
    disabled: true,
  },
];
