import { FormRow } from '@components/form-row.tsx';
import { optionsUrl } from '@core/enums';
import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { TextInput, useNotify, useRefresh } from 'react-admin';

export const UploadTemplateFile = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [file, setFile] = useState(null);
  const [indorsement, setIndorsement] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleIndorsementChange = (e) => {
    setIndorsement(e.target.files[0]);
  };

  const handleUpload = async (patch: string, fileData) => {
    if (!fileData) {
      notify('No file selected', { type: 'warning' });
      return;
    }
    const formData = new FormData();
    formData.append('file', fileData);

    try {
      const auth = JSON.parse(localStorage.getItem('auth') || '{}');
      const response = await fetch(`${optionsUrl.proxyUploadUrl}/template/${record.id}/${patch}`, {
        method: 'POST',
        body: formData,
        headers: {
          'env-name': localStorage.getItem('env') || '',
          'Authorization': `Bearer ${auth.token}`,
        },
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }
      notify('File uploaded successfully', { type: 'success' });
      setFile(null);
      refresh();
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  return (
    <>
      <Card style={{ margin: '1em', minWidth: '800px' }}>
        <CardContent>
          <Typography variant="h6">Main template file</Typography>
          <br />
          <TextInput name="fileUrl" source="fileUrl" disabled value={record?.fileUrl || ''} fullWidth />
          <FormRow width="100%">
            <TextField
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: '.pdf' }}
            />
            <Button onClick={() => handleUpload('file/upload', file)} color="primary" variant="contained">
              Upload
            </Button>
          </FormRow>
        </CardContent>
      </Card>

      <Card style={{ margin: '1em', minWidth: '800px' }}>
        <CardContent>
          <Typography variant="h6">Indorsement file</Typography>
          <br />
          <TextInput name="indorsementUrl" source="indorsementUrl" value={record?.indorsementUrl || ''} disabled
                     fullWidth />
          <FormRow width="100%">
            <TextField
              type="file"
              onChange={handleIndorsementChange}
              inputProps={{ accept: '.pdf' }}
            />
            <Button onClick={() => handleUpload('indorsement/upload', indorsement)} color="primary" variant="contained">
              Upload
            </Button>
          </FormRow>
        </CardContent>
      </Card>
    </>
  );
};
