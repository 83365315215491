import Box from '@mui/material/Box';
import React, { Children } from 'react';
interface IFormRowProps {
  children: React.ReactNode[];
  width?: string
}
export const FormRow: React.FC<IFormRowProps> = ({ children, width =  '600px' }) => {
  return (
    <Box display={{ xs: 'block', sm: 'flex', width }}>
      {Children.map(children, child =>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          {child}
        </Box>
      )}
    </Box>
  );
};
