import { optionsUrl } from '@core/enums';
import { fetchClient } from '@core/utils';
import ReplayIcon from '@mui/icons-material/Replay';
import { useState } from 'react';
import { Button, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const ReInviteUserButton = ({ disabled, name }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  let deactivate: boolean;
  if (disabled) {
    deactivate = true;
  } else {
    deactivate = loading;
  }

  const handleClick = () => {
    setLoading(true);
    fetchClient(optionsUrl.proxyAdminUrl + `/user/${record.id}/regenerate-invitation`, {
      method: 'GET',
    }, notify).then(data => {
      data.json().then(function(data) {
        if (data.status === 'error') {
          notify(`${data.message}`, { type: 'warning' });
          return;
        }
        redirect(
          '/user-custom/invitation/confirmation',
          null,
          null,
          {},
          { data },
        );
        notify('User Re-invited!', { type: 'success' });
      }).catch(function(e) {
        notify(e);
        redirect('/user');
      });
    }).catch(() => {
      deactivate = false;
      notify('Error: user invitation failed', { type: 'warning' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to reinvite',
      message: 'Are you sure to reinvite current user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClick(),
        },
        {
          label: 'No',
          onClick: () => redirect('/user'),
        },
      ],
    });
  };
  return (
    <Button
      label={name}
      onClick={submit}
      disabled={deactivate}
    >
      <ReplayIcon />
    </Button>
  );
};
