import { HttpStatus } from '@core/enums';
import { ErrorHandler } from '@core/utils/error-handler';
import { fetchUtils, useNotify } from 'react-admin';

export const httpHeader = (headerOptions: Record<string, string> = {}): Headers => {
  const header = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'env-name': localStorage.getItem('env') || '',
  });

  Object.entries(headerOptions).forEach(([key, value]) => {
    if (typeof value === 'string') {
      header.set(key, value);
    }
  });

  const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  if (auth.token) {
    header.set('Authorization', `Bearer ${auth.token}`);
  }

  return header;
};

export interface Options extends RequestInit {
  method?: string;
  user?: {
    authenticated?: boolean;
    token?: string;
  };
  headers?: Headers;
}

const setupHeaders = (options: Options): void => {
  if (!options.headers) {
    options.headers = httpHeader();
  } else {
    const standardHeaders = httpHeader();
    standardHeaders.forEach((value, key) => {
      if (!options.headers!.has(key)) {
        options.headers!.set(key, value);
      }
    });
  }
};

const serializeBody = (options: Options): void => {
  if (['POST', 'PUT', 'PATCH'].includes(options.method || '') && options.body && typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body);
  }
  if (['POST'].includes(options.method || '') && !options.body) { //post cant be empty
    options.body = JSON.stringify({});
  }
};

export const httpClient = async <T = any>(
  url: string,
  options: Options = {},
  notify?: ReturnType<typeof useNotify>
): Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: T;
}> => {
  setupHeaders(options);
  serializeBody(options);

  try {
    const payload = await fetchUtils.fetchJson(url, options);
    if (!payload.status) {
      ErrorHandler(payload.status as HttpStatus, notify);
      throw new Error(`HTTP error! status: ${payload.status}`);
    }
    return { ...payload };
  } catch (error) {
    if (!notify) {
      console.error(`HTTP Client error: ${error}`);
    }
    throw error;
  }
};

export const fetchClient = async (
  url: string,
  options: Options = {},
  notify?: ReturnType<typeof useNotify>
): Promise<Response> => {
  setupHeaders(options);
  serializeBody(options);

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      ErrorHandler(response.status as HttpStatus, notify);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (!notify) {
      console.error(`HTTP Client error: ${error}`);
    }
    throw error;
  }
};
