import { TruncatedTextField } from '@core/components';
import { userStatusEnum } from '@core/enums';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Fragment, useState, SyntheticEvent } from 'react';
import { ChipField, Datagrid, DateField, EmailField, TextField, useListContext, useRecordContext } from 'react-admin';
import { DeleteUserButton } from '../components/delete-user-button.tsx';
import { ReInviteUserButton } from 'src/resources/user/components/re-invite-user-button.tsx';
import { RestoreAccessUserButton } from '../components/restore-access-user-button.tsx';
import { SuspendUserButton } from '../components/suspend-user-button.tsx';

interface UserRecord {
  status: string;
}

const ConditionalButtons = () => {
  const record = useRecordContext<UserRecord>();

  switch (record.status) {
    case userStatusEnum.Deactivated:
      return <RestoreAccessUserButton disabled={false} name={'restore access'} />;
    case userStatusEnum.Suspended:
      return (
        <>
          <RestoreAccessUserButton disabled={false} name={'restore access'} />
          <DeleteUserButton disabled={false} name={'delete'} />
        </>
      );
    case userStatusEnum.Active:
      return (
        <>
          <SuspendUserButton disabled={false} name={'suspend'} />
          <DeleteUserButton disabled={false} name={'delete'} />
        </>
      );
    case userStatusEnum.Invited:
      return (
        <>
          <ReInviteUserButton disabled={false} name={'reinvite'} />
          <DeleteUserButton disabled={false} name={'delete'} />
        </>
      );
    default:
      return null;
  }
};

const UserListTabbedDatagrid = () => {
  const context:any = useListContext();
  const [tabValue, setTabValue] = useState<string>(
    context.filterValues?.status === userStatusEnum.Deactivated ? userStatusEnum.Deactivated : 'active'
  );

  const tabs = [
    { id: 'active', name: 'Active' },
    { id: userStatusEnum.Deactivated, name: 'Deactivated' },
  ];

  const handleChange = (_: SyntheticEvent, value: string) => {
    if (value !== tabValue) {
      setTabValue(value);
      context.setFilters({
        ...context.filterValues,
        status: value === 'active' ? null : userStatusEnum.Deactivated,
        role: value === 'active' ? context.filterValues.role : null,
      });
    }
  };

  return (
    <Fragment>
      <Tabs centered value={tabValue} indicatorColor="primary" onChange={handleChange} >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <EmailField source="email" />
        <TruncatedTextField source="firstName" maxLength={30} />
        <TruncatedTextField source="lastName" maxLength={30} />
        <TruncatedTextField source="companyName" maxLength={30} />
        <ChipField source="companyStatus" />
        <TextField source="role" />
        <DateField source="createdAt" />
        <ChipField source="status" />
        <TextField source="onboardingStatus" />
        {tabValue === userStatusEnum.Deactivated && <DateField source="deactivatedAt" />}
        <ConditionalButtons />
      </Datagrid>
    </Fragment>
  );
};

export { ConditionalButtons, UserListTabbedDatagrid };
