import { optionsUrl, resourceEnum } from '@core/enums';
import { fetchClient } from '@core/utils';
import {
  AutocompleteInput,
  CreateProps,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

interface CreateToolbarProps {
  templateId: number;
  handleClose: () => void;
}

const CreateToolbar = ({ templateId, handleClose }: CreateToolbarProps) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const { reset, getValues } = useFormContext();

  const handleSave = async () => {
    try {
      const formValues = getValues();
      const companyId = formValues['companyId'];

      const response = await fetchClient(
        `${optionsUrl.proxyAdminUrl}/${resourceEnum.template}/${templateId}/company-reference/${companyId}`,
        {
          method: 'PATCH',
        },
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Unknown error occurred');
      }
      const { affected } = await response.json();
      if (affected) {
        notify('Template reference has been created!', { type: 'success' });
      }else {
        notify('Template reference alredy Exist!', { type: 'info' });
      }
      handleClose();
    } catch (error) {
      console.log(error);
      notify('Template reference creating error', { type: 'warning' });
    } finally {
      redirect(`/${resourceEnum.template}/${templateId}`);
      refresh();
    }
  };

  return (
    <Toolbar >
      <SaveButton
        type="button"
        label="Save"
        variant="text"

        mutationOptions={{
          onSuccess: async () => {
            reset();
            window.scrollTo(0, 0);
            handleSave();
          },
        }}
      />
    </Toolbar>
  );
};

interface CreateReferenceCompanyProps extends CreateProps {
  templateId: number;
  templateName: string;
  handleClose: () => any;
}

export const CreateReferenceCompany = ({ templateId, templateName, handleClose }: CreateReferenceCompanyProps) => (
  <div style={{ margin: '1px' }}>
    <div> From template: <b> {templateName} </b></div>
    <div style={{ marginTop: '10px' }}> to:</div>
    <SimpleForm toolbar={<CreateToolbar templateId={templateId} handleClose={handleClose} />}>
      <ReferenceInput label="Company" name="companyId" filter={{type:'regular',status: 'trusted'}} source='' reference={resourceEnum.company} >
        <AutocompleteInput
          name="companyId"
          source="Company"
          label="Company"
          optionValue="id"
          optionText="companyName"
          style={{ width: 500 }}
          filterToQuery = {(searchText:string) => ({ q: `${searchText}`, type:'regular',status: 'trusted'})}
        />
      </ReferenceInput>
    </SimpleForm>
  </div>
);
