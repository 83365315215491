import Typography from '@mui/material/Typography';
import { LoadingIndicator } from 'react-admin';

export const AppBarToolbar = () => (
    <>
      <Typography variant="inherit" color="wait">
        {localStorage.getItem('env')}
      </Typography>
        <LoadingIndicator />
    </>
);
