import { FormRow } from '@components/form-row.tsx';
import { PhoneNumberInput } from '@components/phone-number-input.tsx';
import {
  userOnboardingStatusEnum,
  userStatusEnum,
  userVerificationStatusEnum,
  userVerificationTypeEnum,
} from '@core/enums';
import { HumanNameValidator } from '@core/validators/human-name-validator.ts';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  DateInput,
  Edit,
  email,
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { VerifyComponent } from './custom-pages/user-verifycation-confirm.tsx';

const transformUser = (data) => ({
  ...data,
  phoneNumber: data.phoneNumber || null,
});

const UserEditToolbar = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
    <Toolbar {...props}>
      <SaveButton type="button" transform={transformUser} />
      <div style={{ margin: '1em' }}>
        <Button
          label={'Cancel'}
          onClick={() => {
            redirect(`/user`);
            refresh();
          }}
          variant="contained"
          size="medium"
        >
          <CancelIcon />
        </Button>
      </div>
    </Toolbar>
  );
};

const UserEditForm = () => {
  const record = useRecordContext();

  const isVerificationConfirmationAllowed = ![
    userStatusEnum.Suspended,
    userStatusEnum.Deactivated,
  ].includes(record.status);

  const isOtpPassed = ![
    userOnboardingStatusEnum.companyProfile,
    userOnboardingStatusEnum.tempPassword,
    userOnboardingStatusEnum.userProfile,
    userOnboardingStatusEnum.twoFA,
  ].includes(record.onboardingStatus);

  const isKycPassed = record.userVerification?.some(
    item =>
      item.verificationType === userVerificationTypeEnum.KYC &&
      item.verificationStatus ===
      userVerificationStatusEnum.VerificationPassed,
  );

  return (
    <>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput name="id" source="id" disabled style={{ width: '200px' }} />
        <FormRow width="600px">
          <TextInput
            name="firstName"
            source="firstName"
            disabled={isKycPassed}
            style={{ width: 300 }}
            validate={[
              required(),
              minLength(3),
              maxLength(30),
              HumanNameValidator,
            ]}
          />
          <TextInput
            name="lastName"
            source="lastName"
            disabled={isKycPassed}
            style={{ width: 300 }}
            validate={[
              required(),
              minLength(3),
              maxLength(30),
              HumanNameValidator,
            ]}
          />
        </FormRow>
        <FormRow width="600px">
          <TextInput
            source="email"
            name="email"
            disabled
            style={{ width: 300 }}
            validate={[required(), email()]}
          />
          <PhoneNumberInput
            disabled={isOtpPassed}
            source="phoneNumber"
            style={{ width: 300 }}
          />
        </FormRow>
        <TextInput name="status" source="status" disabled/>
        <DateInput name="createdAt" source="createdAt" disabled/>
        <FormRow width="600px">
          <TextInput name="role" source="role" disabled style={{ width: 300 }} />
          <TextInput name="company" source="company" disabled style={{ width: 300 }} />
        </FormRow>
      </SimpleForm>
      {isVerificationConfirmationAllowed && (
        <VerifyComponent data={record} />
      )}
    </>
  );
};

export const UserEdit = props => (
  <Edit {...props}>
    <UserEditForm {...props} />
  </Edit>
);

