import { optionsUrl } from '@core/enums';
import { httpClient } from '@core/utils';
import { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AutocompleteInput, AutocompleteInputProps } from 'react-admin';

interface IRows {
  id: string;
}

interface IDataResponse {
  data: IRows[];
  paging: { total: number };
}

export const AutocompleteSelectInput = (props: AutocompleteInputProps & {
  patch: string,
  filter?: string
  url?: string
  respToRows?: (data: any) => { setRows(data)};
}) => {
  const [rows, setRows] = useState<IRows[]>([]);
  const optionValue = props.optionValue || 'id';
  const optionText = props.optionText || 'name';

  useEffect(() => {
    const fetchDataAsync = async () => {
      const filter = (props.filter) ? `?filter=${props.filter}` : '';
      const url = props.url || optionsUrl.proxyAdminUrl;
      const { json:data } = await httpClient< IRows[] | IDataResponse>(`${url}/${props.patch}${filter}`);
      if (Array.isArray(data)) {
        setRows(data);
      } else if (data?.data?.length) {
        setRows(data.data);
      } else {
        setRows([]);
      }
    };
    fetchDataAsync();
  }, [props.patch, props.filter, props.url]);

  return (
    <AutocompleteInput
      {...props}
      optionValue={optionValue}
      optionText={optionText}
      choices={rows}
    />
  );
};
