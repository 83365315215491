export const optionsUrl = Object.freeze({
  proxyUrl:
      process.env.TECH_BASE_URL || 'https://tech-admin.secro.io/api',
  proxyAdminUrl:
    (process.env.TECH_BASE_URL  || 'https://tech-admin.secro.io/api')+'/proxy/admin',
  proxyUploadUrl:
    (process.env.TECH_BASE_URL  || 'http://localhost:3000/api')+'/proxy/upload/admin',
  proxyDictionaryUrl:
    (process.env.TECH_BASE_URL  || 'https://tech-admin.secro.io/api')+'/proxy/dictionary',
});
