import { optionsUrl } from '@core/enums';
import { fetchClient } from '@core/utils';
import BlockIcon from '@mui/icons-material/Block';
import { useState } from 'react';
import { Button, useNotify, useRecordContext, useRedirect, useRefresh } from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const DeleteTemplateReferenceButton = ({ companyId, name}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  let deactivate: boolean = loading;

  const handleClick = async () => {
    setLoading(true);
    fetchClient(optionsUrl.proxyAdminUrl + `/template/${record.id}/company-reference/${companyId}`, {
      method: 'DELETE',
    }, notify).then(response => {
      if (response.status === 200) {
        notify('Reference Deleted!', { type: 'success' });
        redirect(`/company/${companyId}`);
        refresh();
        return;
      }
      response.json().then(data => {
        if (data.status === 'error') {
          notify(`${data.message}`, { type: 'warning' });
          redirect(`/company/${companyId}`);
          return;
        }
      });
    }).catch(() => {
      deactivate = false;
      notify('Error: reference deletion failed', { type: 'warning' });
      redirect(`/company/${companyId}`);
      return;
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete current reference?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClick(),
        },
        {
          label: 'No',
          onClick: () => redirect(`/template/${companyId}`),
        },
      ],
    });
  };
  return (
    <Button
      label={name}
      onClick={submit}
      disabled={deactivate}
    >
      <BlockIcon />
    </Button>
  );
};

