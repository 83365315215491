import { companyVerificationStatusEnum, companyVerificationTypeEnum, optionsUrl } from '@core/enums';
import { fetchClient } from '@core/utils';
import { Typography } from '@mui/material';
import {
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const VerifyComponent = props => {
  const record = props.data;
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  // eslint-disable-next-line prefer-const
  let verificationTypeData;

  const handleConfirm = async data => {
    try {
      const response = await fetchClient(
        optionsUrl.proxyAdminUrl + `/company/confirm-verification`,
        {
          method: 'PUT',
          body: JSON.stringify({
            adminApproveDescription: data.adminApproveDescription,
            companyVerificationType:
            verificationTypeData.verificationType,
            companyId: record.id,
          }),
        }, notify,
      );
      if (response.ok) {
        redirect(`/company/${record.id}`);
        refresh();
        notify('Verification successfully confirmed!', {
          type: 'success',
        });
      } else {
        notify('Verification confirmation failed!', { type: 'warning' });
        redirect('/company');
      }
    } catch (err) {
      notify('Verification confirmation failed!', { type: 'warning' });
      redirect('/company');
    }
  };

  const handleAlert = event => {
    confirmAlert({
      title: `Confirm Manual Verification`,
      message: `Are you sure to set ${verificationTypeData.verificationType} to verified for ${record.companyName} company`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleConfirm(event),
        },
        {
          label: 'No',
          onClick: () => {
            redirect(`/company/${record.id}`);
            refresh();
          },
        },
      ],
    });
  };

  const kybdata = record.companyVerifications?.find(
    item =>
      item.verificationType === companyVerificationTypeEnum.KYB &&
      item.verificationStatus ===
      companyVerificationStatusEnum.VerificationNotPassed,
  );
  const amlData = record.companyVerifications?.find(
    item =>
      item.verificationType === companyVerificationTypeEnum.AML &&
      item.verificationStatus ===
      companyVerificationStatusEnum.VerificationNotPassed,
  );
  const bavData = record.companyVerifications?.find(
    item =>
      item.verificationType === companyVerificationTypeEnum.BAV &&
      item.verificationStatus ===
      companyVerificationStatusEnum.VerificationNotPassed,
  );

  verificationTypeData = kybdata || amlData || bavData;

  const VerifyCompanyToolbar = props => {
    return (
      <Toolbar {...props}>
        <SaveButton
          label={'Confirm'}
          type="button"
          mutationOptions={{
            onSuccess: e => handleAlert(e),
          }}
        />
      </Toolbar>
    );
  };

  const VerifyCompanyForm = props => (
    <div
      style={{
        marginLeft: 'auto',
        borderLeft: '2px solid',
        borderColor: '#a9a9a938',
      }}
    >
      <SimpleForm toolbar={<VerifyCompanyToolbar {...props} />}>
        <Typography variant="h6" gutterBottom>
          {`Verify Company ${verificationTypeData.verificationType}`}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ marginTop: '20px', maxWidth: '300px' }}
        >
          {`Company ${verificationTypeData.verificationType} verification is failed with reason "${verificationTypeData.failReason}"`}
        </Typography>
        <TextInput
          style={{ marginTop: '20px' }}
          label="Verification Description"
          name="adminApproveDescription"
          source="adminApproveDescription"
          validate={(required(), minLength(3), maxLength(100))}
        />
      </SimpleForm>
    </div>
  );

  return verificationTypeData && <VerifyCompanyForm />;
};

