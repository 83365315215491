import { optionsUrl, resourceEnum } from '@core/enums';
import { fetchClient } from '@core/utils';
import ReplayIcon from '@mui/icons-material/Replay';
import { useState } from 'react';
import {
  Button,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const ActivateTemplateButton = ({ disabled, name }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const deactivate = disabled || loading;

  const handleClick = async () => {
    setLoading(true);

    try {
      await fetchClient(
          `${optionsUrl.proxyAdminUrl}/${resourceEnum.template}/${record.id}/status/published`,
          {
            method: 'PATCH',
          },
        notify
      );
      notify('Template Activated!', { type: 'success' });
      redirect(`/${resourceEnum.template}`);
      refresh();
    } catch (error) {
      notify(error.message, { type: 'warning' });
      redirect(`/${resourceEnum.template}`);
    } finally {
      setLoading(false);
    }
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to activate',
      message: 'Are you sure to activate the current Template?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => await handleClick(),
        },
        {
          label: 'No',
          onClick: () => redirect(`/${resourceEnum.template}`),
        },
      ],
    });
  };

  return (
      <Button label={name} onClick={submit} disabled={deactivate}>
        <ReplayIcon />
      </Button>
  );
};
