import { optionsUrl } from '@core/enums';
import { httpClient } from '@core/utils';
import { stringify } from 'query-string';
import { DataProvider, GetListResult, RaRecord } from 'react-admin';

const proxyAdminUrl = optionsUrl.proxyAdminUrl;

export const dataProvider = {
  getList: async (resource, params) => {
    try {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const timezoneOffset = -1 * new Date().getTimezoneOffset();
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        timeZoneOffset: JSON.stringify(timezoneOffset),
      };
      const url = `${proxyAdminUrl}/${resource}?${stringify(query)}`;
      const { json } = await httpClient(url);
      return {
        data: json.data || [],
        total: json?.paging?.total || 0,
      };
    } catch (error) {
      console.error('getList | Fetch data error: ' + error);
      return {
        data: [],
        total: 0,
      };
    }
  },
  getManyReference: async (resource, params) => {
    try {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const timezoneOffset = -1 * new Date().getTimezoneOffset();
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
        timeZoneOffset: JSON.stringify(timezoneOffset),
      };
      const url = `${proxyAdminUrl}/${resource}?${stringify(query)}`;
      const { json } = await httpClient(url);
      return {
        data: json.data || [],
        total: json?.paging?.total || 0,
      };
    } catch (error) {
      console.error('getManyReference | Fetch data error: ' + error);
      return {
        data: [],
        total: 0,
      };
    }
  },

  getOne: async (resource, params) => {
    try {
      const { json } = await httpClient(`${proxyAdminUrl}/${resource}/${params.id}`);
      return { data: json };
    } catch (error) {
      console.error('getOne | Fetch data error: ' + error);
      throw error;
    }
  },

  getMany: async (resource, params) => {
    try {
      const query = { filter: JSON.stringify({ ids: params.ids }) };
      const url = `${proxyAdminUrl}/${resource}?${stringify(query)}`;
      const { json } = await httpClient(url);
      return { data: json };
    } catch (error) {
      console.error('getMany | Fetch data error: ' + error);
      throw error;
    }
  },

  create: async (resource, params) => {
    try {
      const { json } = await httpClient(`${proxyAdminUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      });
      return { data: { ...params.data, id: json.id } };
    } catch (error) {
      console.error('create | Fetch data error: ' + error);
      throw error;
    }
  },

  update: async (resource, params) => {
    try {
      const { json } = await httpClient(`${proxyAdminUrl}/${resource}/${params.id}`, {
        method: 'PATCH',
        body: JSON.stringify(params.data),
      });
      return { data: json };
    } catch (error) {
      console.error('update | Fetch data error: ' + error);
      throw error;
    }
  },

  updateMany: async (resource, params) => {
    try {
      const query = { filter: JSON.stringify({ id: params.ids }) };
      const { json } = await httpClient(`${proxyAdminUrl}/${resource}?${stringify(query)}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      });
      return { data: json };
    } catch (error) {
      console.error('updateMany | Fetch data error: ' + error);
      throw error;
    }
  },

  delete: async (resource, params) => {
    try {
      const { json } = await httpClient(`${proxyAdminUrl}/${resource}/${params.id}`, {
        method: 'DELETE',
      });
      return { data: json };
    } catch (error) {
      console.error('delete | Fetch data error: ' + error);
      throw error;
    }
  },

  deleteMany: async <RecordType extends RaRecord = any>(
    resource: string,
    params: any
  ): Promise<GetListResult<RecordType>> => {
    try {
      const query = { filter: JSON.stringify({ id: params.ids }) };
      const { json } = await httpClient(`${proxyAdminUrl}/${resource}?${stringify(query)}`, {
        method: 'DELETE',
        body: params.data,
      });
      return { data: json };
    } catch (error) {
      console.error('deleteMany | Fetch data error: ' + error);
      throw error;
    }
  },
} as DataProvider;
