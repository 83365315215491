import { AutocompleteRefInput } from '@components/autocomplete-ref-input.tsx';
import { TruncatedTextField } from '@core/components';
import { resourceEnum } from '@core/enums';
import {
  ChipField,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  List,
  Pagination,
  RaRecord,
  ResourceContextProvider,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

const reviewFilters = [
  <TextInput id="q" name="q" source="q" label={'Id or BL Number'}  style={{ width: 200 }} alwaysOn />,
  <DateInput name="createdAfter" source="createdAfter" label="Created After" alwaysOn />,
  <DateInput name="createdBefore" source="createdBefore" label="Created Before" alwaysOn  />,
  <AutocompleteRefInput
    name="templateId"
    source="templateId"
    label="Template"
    reference={resourceEnum.template}
    filterToQuery={(searchText: string) => ({ q: `${searchText}` })}
    style={{ width: 600 }}
    optionValue="id"
    optionText="shortName"
    alwaysOn
  />,
  <AutocompleteRefInput
    name="ownerId"
    source="ownerId"
    label="Drafting Entity"
    reference={resourceEnum.company}
    filterToQuery={(searchText: string) => ({ q: `${searchText.trim()}` })}
    refFilter={{ type: 'regular' }}
    style={{ width: 600 }}
    optionValue="id"
    optionText={(el?: {id:number, companyName:string}) =>
      el?.companyName?.trim() || ''
    }
    alwaysOn
  />,
  <AutocompleteRefInput
    name="signingId"
    source="signingId"
    label="Signing Entity"
    reference={resourceEnum.company}
    filterToQuery={(searchText: string) => ({ q: `${searchText.trim()}` })}
    refFilter={{ type: 'regular' }}
    style={{ width: 600 }}
    optionValue="id"
    optionText={(el?: {id:number, companyName:string}) =>
      el?.companyName?.trim() || ''
    }
    alwaysOn
  />,
];
const PostPagination = () => <Pagination rowsPerPageOptions={[10, 100, 500, 1000]} />;

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

const hideColumns = [
'vaultStatus',
'voyageNumber',
'notifyPartyName',
// 'vesselName',
// 'draftingEntity',
// 'signingEntity',
'voyageNumber',
'loadingPortName',
'loadingPortCountry',
'dischargingPortName',
'dischargingPortCountry',
'entityRequestingConvertToPaper',
'wasReissueAsEBL',
'companyReissueAsEBL',
'wasConvertToPaper',
'wasReissueAsPaperBL',
'bLConfirmedBy',
'negotiability',
'bLSurrendered',
'bLDischarged',
'companyConvertToPaper',
'parentID',
'shipperNameVerified',
'consigneeNameVerified',
]

export const AnalyticList = (props: RaRecord) => {
  return (
    <ResourceContextProvider value={resourceEnum.analytic}>
      <List
        {...props}
        filters={reviewFilters}
        actions={<ListActions />}
        pagination={<PostPagination />}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <DatagridConfigurable rowClick={false} bulkActionButtons={false} size={'small'} omit={hideColumns}>
          <TextField source="id" title="Document ID" />
          <TruncatedTextField source="blNumber" title="BL Number" sortBy="blNumber" maxLength={50} />
          <ChipField source="negotiationStatus" title="Negotiation Status" sortBy="status" />
          <TextField sortable={false} source="vaultStatus" title="Vault Status" />
          <DateField source="negotiationCreatedAt" title="Created. Negotiation" sortBy="createdAt" />
          <DateField sortable={false} source="negotiationUpdatedAt" title="Updated. Negotiatio" />
          <ChipField sortable={false} source="bLType" title="BL Type" />
          <ChipField sortable={false} source="bLTemplate" title="BL Template" />
          <TruncatedTextField maxLength={50} sortable={false} source="draftingEntity" title="Drafting Company" />
          <TruncatedTextField maxLength={50} sortable={false} source="signingEntity" title="Signing Entity" />
          <TruncatedTextField maxLength={50} sortable={false} source="shipperNameBL" title="Shipper Name on BL"  />
          <TruncatedTextField maxLength={50} sortable={false} source="consigneeNameBL" title="Consignee Name on BL" />
          <TruncatedTextField maxLength={50} sortable={false} source="notifyPartyName" title="Notify Party Name" />
          <TruncatedTextField maxLength={50} sortable={false} source="vesselName" title="Vessel Name" />
          <TruncatedTextField maxLength={50} sortable={false} source="voyageNumber" title="Voyage Number" />
          <TruncatedTextField maxLength={50} sortable={false} source="loadingPortName" title="Loading Port Name" />
          <TruncatedTextField maxLength={50} sortable={false} source="loadingPortCountry" title="Loading Port Country" />
          <TruncatedTextField maxLength={50} sortable={false} source="dischargingPortName" title="Discharging Port name" />
          <TruncatedTextField maxLength={50} sortable={false} source="dischargingPortCountry" title="Discharging Port Country" />
          <TruncatedTextField maxLength={50} sortable={false} source="entityRequestingConvertToPaper" title="Entity requesting reissue as paper BL" />
          <TruncatedTextField maxLength={50} sortable={false} source="wasReissueAsEBL" title="Was “reissue as eBL” requested" />
          <TruncatedTextField maxLength={50} sortable={false} source="companyReissueAsEBL" title="Entity requesting reissue as eBL" />
          <TruncatedTextField maxLength={50} sortable={false} source="wasConvertToPaper" title="Was “convert to paper” requested" />
          <TruncatedTextField maxLength={50} sortable={false} source="wasReissueAsPaperBL" title="Was “reissue as paper BL” requested" />
          <TruncatedTextField maxLength={50} sortable={false} source="bLConfirmedBy" title="BL confirmed by" />
          <TruncatedTextField maxLength={50} sortable={false} source="negotiability" title="Negotiable" />
          <TruncatedTextField maxLength={50} sortable={false} source="bLSurrendered" title="BL surrendered" />
          <TruncatedTextField maxLength={50} sortable={false} source="bLDischarged" title="BL discharged" />
          <TruncatedTextField maxLength={50} sortable={false} source="companyConvertToPaper" title="Entity requesting convert to paper" />
          <TruncatedTextField maxLength={50} sortable={false} source="parentID" title="parentID" />
          <TruncatedTextField maxLength={50} sortable={false} source="shipperNameVerified" title="Shipper. Verified"  />
          <TruncatedTextField maxLength={50} sortable={false} source="consigneeNameVerified" title="Consignee Verified" />
        </DatagridConfigurable>
      </List>
    </ResourceContextProvider>
  );
};
