import { FormRow } from '@components/form-row';
import { PhoneNumberInput } from '@components/phone-number-input';
import { CreateInDialogButton, AutocompleteDictionaryInput } from '@core/components';
import {
  companyStatusEnum,
  companyVerificationStatusEnum,
  companyVerificationTypeEnum,
  dictionaryEndpointsEnum,
  resourceEnum,
} from '@core/enums';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CreateReferenceTemplate } from '@resources/company/components/create-reference-template';
import { DeleteTemplateReferenceButton } from '@resources/company/components/delete-template-reference-button';
import {
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateInput,
  Edit,
  FunctionField,
  maxLength,
  minLength,
  ReferenceManyField,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
  useRedirect,
  useRefresh,
  WithRecord,
} from 'react-admin';
import { VerifyComponent } from './custom-pages/company-verifycation-confirm.tsx';

const transformCompany = data => ({
  ...data,
  phoneNumber: data.phoneNumber || null,
});

const CompanyEditToolbar = props => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
    <Toolbar {...props}>
      <SaveButton type="button" transform={transformCompany} />
      <div style={{ margin: '1em' }}>
        <Button
          label={'Cancel'}
          onClick={() => {
            redirect(`/company`);
            refresh();
          }}
          variant="contained"
          size="medium"
        >
          <CancelIcon />
        </Button>
      </div>
    </Toolbar>
  );
};

const CompanyEditForm = () => {
  const record = useRecordContext();
  const isKybPassed = record.companyVerifications?.some(
    (item) =>
      item.verificationType === companyVerificationTypeEnum.KYB &&
      item.verificationStatus === companyVerificationStatusEnum.VerificationPassed,
  );
  const isVerificationConfirmationAllowed = ![
    companyStatusEnum.Suspended,
    companyStatusEnum.Deactivated,
    companyStatusEnum.Trusted,
  ].includes(record.status);
  const convertEmptyStringToUndefined = v => (v === '' ? null : v);

  return (
    <>
      <SimpleForm toolbar={<CompanyEditToolbar />}>
        <TextInput name="id" source="id" disabled />
        <TextInput
          name="companyName"
          source="companyName"
          fullWidth
          validate={[required(), minLength(3), maxLength(80)]}
        />
        <TextInput
          name="taxRegistrationNumber"
          validate={[required()]}
          source="taxRegistrationNumber"
          fullWidth
          disabled={isKybPassed}
        />
        <FormRow width="100%">
          <div>
            <TextInput
              name="stateOrRegion"
              source="stateOrRegion"
              validate={[minLength(3), maxLength(100)]}
              fullWidth
              parse={convertEmptyStringToUndefined}
            />
            <TextInput
              name="address"
              source="address"
              validate={[minLength(3), maxLength(300)]}
              fullWidth
              parse={convertEmptyStringToUndefined}
            />
            <FormRow width="100%">
              <PhoneNumberInput source="phoneNumber" style={{ width: '300px' }} />
              <TextInput
                name="rapcPhoneNumber"
                source="rapcPhoneNumber"
                disabled
                style={{ width: '300px' }}
              />
            </FormRow>
          </div>
          <div>
            <FormRow width="600px">
              <TextInput name="status" source="status" disabled fullWidth />
              <TextInput name="rapcFirstName" source="rapcFirstName" disabled fullWidth />
            </FormRow>
            <FormRow width="600px">
              <TextInput name="rapcLastName" source="rapcLastName" disabled fullWidth />
              <TextInput name="rapcMail" source="rapcMail" disabled fullWidth />
            </FormRow>
            <DateInput name="createdAt" source="createdAt" disabled fullWidth />
          </div>
        </FormRow>
        <FormRow width="100%">

          <AutocompleteDictionaryInput
            name="jurisdictionCode"
            source="jurisdictionCode"
            patch={dictionaryEndpointsEnum.jurisdictionCodes}
            fullWidth
            optionText="name"
            optionValue="code"
            disabled={isKybPassed}
          />
          <AutocompleteDictionaryInput
            name="country"
            source="country"
            patch={dictionaryEndpointsEnum.countries}
            validate={[required()]}
            fullWidth
            optionText="name"
            optionValue="alpha_2"
            parse={convertEmptyStringToUndefined}
          />
        </FormRow>
        <br />
        <Box style={{ width: '100%' }}>
          <Typography component="h1" variant="h5">
            Related private templates:
          </Typography>
          <ReferenceManyField
            label="Сompanies"
            reference={resourceEnum.template}
            target="companyId"
            perPage={100}
          >
            <Datagrid bulkActionButtons={false} style={{ width: '100%' }}>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="shortName" />
              <ChipField source="status" />
              <FunctionField
                label="is private"
                render={v => (v?.companies?.length ? 'yes' : '')}
              />
              <DeleteTemplateReferenceButton companyId={record.id} name="Delete" />
            </Datagrid>
          </ReferenceManyField>
          <WithRecord
            render={record => (
              <CreateInDialogButton title="Add template Reference">
                {handleClose => (
                  <CreateReferenceTemplate
                    companyId={record.id}
                    companyName={record.companyName}
                    handleClose={handleClose}
                  />
                )}
              </CreateInDialogButton>
            )}
          />
        </Box>

        <Box style={{ width: '100%' }}>
          <Typography component="h1" variant="h5">
            Related specified feature flags:
          </Typography>
          <ReferenceManyField
            label="FeatureFlag"
            reference={resourceEnum.featureFlag}
            target="companyId"
            perPage={100}
          >
            <Datagrid bulkActionButtons={false} style={{ width: '100%' }}>
              <TextField source="id" />
              <TextField source="name" />
              <BooleanField source="value" />
            </Datagrid>
          </ReferenceManyField>
        </Box>
      </SimpleForm>
      {isVerificationConfirmationAllowed && <VerifyComponent data={record} />}
    </>
  );
};

export const CompanyEdit = props => (
  <Edit {...props}>
    <Box display="flex" flexDirection="column" width="100%" justifyContent="space-between">
      <CompanyEditForm {...props} />
    </Box>
  </Edit>
);
