import {companyStatusEnum, companyTypeEnum, resourceEnum} from '@core/enums';
import {
  DateInput,
  Filter,
  List,
  Pagination,
  ResourceContextProvider,
  SelectInput,
  TextInput,
  useListContext,
} from 'react-admin';
import {CompanyListActions} from './components/company-list.action.tsx';
import {TabbedDatagrid} from './custom-pages/tabbed-datagrid.tsx';

const CompanyPagination = () => (
    <Pagination rowsPerPageOptions={[10, 50, 100]}/>
);

const companyStatusChoices = [
  {name: 'Invited', id: 'invited'},
  {name: 'Trusted', id: 'trusted'},
  {name: 'Unverified', id: 'unverified'},
  {name: 'Suspended', id: 'suspended'},
  {name: 'Deactivated', id: 'deactivated'},
];

const companyOnboardingStatusChoices = [
  {name: 'KYB-VerificationPassed', id: 'KYB-verificationPassed'},
  {name: 'KYB-VerifiedByAdmin', id: 'KYB-verifiedByAdmin'},
  {name: 'KYB-VerificationNotPassed', id: 'KYB-verificationNotPassed'},
  {name: 'AML-VerificationPassed', id: 'AML-verificationPassed'},
  {name: 'AML-VerifiedByAdmin', id: 'AML-verifiedByAdmin'},
  {name: 'AML-VerificationNotPassed', id: 'AML-verificationNotPassed'},
  {name: 'BAV-VerificationPassed', id: 'BAV-verificationPassed'},
  {name: 'BAV-VerifiedByAdmin', id: 'BAV-verifiedByAdmin'},
  {name: 'BAV-VerificationNotPassed', id: 'BAV-verificationNotPassed'},
];

const companyTypeChoices = [
  {name: 'Regular', id: 'regular'},
  {name: 'Indorsee', id: 'indorsee'},
  {name: 'Trading Partner', id: 'tradingPartner'},
];

const CompanyFilter = props => {
  const context = useListContext();
  let filteredCompanyStatusChoices = [...companyStatusChoices];
  let filteredCompanyTypeChoices = [...companyTypeChoices];

  const isOnTabDeactivated =
      context.filterValues && context.filterValues.tabName === 'deactivated';

  const isOnTabCustomerLeads =
      context.filterValues &&
      context.filterValues.tabName === 'customerLeads';

  if (
      context.filterValues &&
      context.filterValues.status !== companyStatusEnum.Deactivated
  ) {
    filteredCompanyStatusChoices = filteredCompanyStatusChoices.filter(
        item => item.id !== companyStatusEnum.Deactivated,
    );
  }

  if (isOnTabCustomerLeads) {
    filteredCompanyTypeChoices = filteredCompanyTypeChoices.filter(
        item => item.id !== companyTypeEnum.Regular,
    );
  }

  return (
      <Filter {...props}>
        <TextInput name="q" label="Search" source="q" alwaysOn/>
        <DateInput name="createdFrom" source="createdFrom" label="Created From" alwaysOn/>
        <DateInput name="createdTill" source="createdTill" label="Created Till" alwaysOn/>
        <SelectInput
            name="status"
            source="status"
            choices={filteredCompanyStatusChoices}
            alwaysOn
            disabled={isOnTabDeactivated || isOnTabCustomerLeads}
        />
        <SelectInput
            label="Onboarding Status"
            name="companyVerifications"
            source="companyVerifications"
            choices={companyOnboardingStatusChoices}
            alwaysOn
            fullWidth
            style={{width: 200}}
        />
        {isOnTabCustomerLeads && (
            <SelectInput
                label="Company type"
                source="type"
                name="type"
                choices={filteredCompanyTypeChoices}
                alwaysOn
                fullWidth
            />
        )}
      </Filter>
  );
};

export const CompanyList = props => {
  return (
      <ResourceContextProvider value={resourceEnum.company}>
        <List
            {...props}
            hasCreate={true}
            empty={false}
            actions={<CompanyListActions/>}
            filters={<CompanyFilter/>}
            pagination={<CompanyPagination/>}
            sort={{ field: 'id', order: 'DESC' }}
        >
          <TabbedDatagrid/>
        </List>
      </ResourceContextProvider>
  );
};
