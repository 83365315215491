import {companyStatusEnum, optionsUrl} from '@core/enums';
import {fetchClient} from '@core/utils';
import BlockIcon from '@mui/icons-material/Block';
import {useState} from 'react';
import {Button, useNotify, useRecordContext, useRedirect, useRefresh} from 'react-admin';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const DeleteCompanyButton = ({disabled, name}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  let deactivate: boolean;
  if (disabled) {
    deactivate = true;
  } else {
    deactivate = loading;
  }

  const handleClick = async () => {
    setLoading(true);
    fetchClient(optionsUrl.proxyAdminUrl + `/company/${record.id}`, {
      method: 'DELETE',
    }, notify).then(response => {
      if (response.status === 200) {
        notify('Company Deleted!', {type: 'success'});
        redirect('/company');
        refresh();
        return;
      }
      response.json().then(data => {
        if (data.status === 'error') {
          notify(`${data.message}`, {type: 'warning'});
          redirect('/company');
          return;
        }
      });
    }).catch(() => {
      deactivate = false;
      notify('Error: rapc deletion failed', {type: 'warning'});
      redirect('/company');
      return;
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete current company?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClick(),
        },
        {
          label: 'No',
          onClick: () => redirect('/company'),
        },
      ],
    });
  };
  return (
      record.status !== companyStatusEnum.Deactivated && (
          <Button
              label={name}
              onClick={submit}
              disabled={deactivate}
          >
            <BlockIcon/>
          </Button>
      )
  );
};

