import {optionsUrl, userVerificationStatusEnum, userVerificationTypeEnum} from '@core/enums';
import {fetchClient} from '@core/utils';
import {Typography} from '@mui/material';
import {
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const VerifyComponent = props => {
  const record = props.data;
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  // eslint-disable-next-line prefer-const
  let verificationTypeData: { verificationType: any; failReason: any; };

  const handleConfirm = async data => {
    try {
      const response = await fetchClient(
          optionsUrl.proxyAdminUrl + `/user/confirm-verification`,
          {
            method: 'POST',
            body: JSON.stringify({
              adminApproveDescription: data.adminApproveDescription,
              userVerificationType:
              verificationTypeData.verificationType,
              userId: record.id,
            }),
          },
      );
      if (response.ok) {
        redirect(`/user/${record.id}`);
        refresh();
        notify('Verification successfully confirmed!', {
          type: 'success',
        });
      } else {
        notify('Verification confirmation failed!', {type: 'warning'});
        redirect('/user');
      }
    } catch (err) {
      notify('Verification confirmation failed!', {type: 'warning'});
      redirect('/user');
    }
  };

  const handleAlert = event => {
    confirmAlert({
      title: `Confirm Manual Verification`,
      message: `Are you sure to set ${verificationTypeData.verificationType} to verified for ${record.firstName} ${record.lastName} user`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleConfirm(event),
        },
        {
          label: 'No',
          onClick: () => {
            redirect(`/user/${record.id}`);
            refresh();
          },
        },
      ],
    });
  };

  const kycData = record.userVerification?.find(
      item =>
          item.verificationType === userVerificationTypeEnum.KYC &&
          item.verificationStatus ===
          userVerificationStatusEnum.VerificationNotPassed,
  );
  const amlIndividualData = record.userVerification?.find(
      item =>
          item.verificationType === userVerificationTypeEnum.AML_Individual &&
          item.verificationStatus ===
          userVerificationStatusEnum.VerificationNotPassed,
  );

  verificationTypeData = kycData || amlIndividualData;

  const VerifyUserToolbar = props => {
    return (
        <Toolbar {...props}>
          <SaveButton
              label={'Confirm'}
              type="button"
              mutationOptions={{
                onSuccess: e => handleAlert(e),
              }}
          />
        </Toolbar>
    );
  };

  const VerifyUserForm = props => (
      <div
          style={{
            marginLeft: 'auto',
            borderLeft: '2px solid',
            borderColor: '#a9a9a938',
          }}
      >
        <SimpleForm toolbar={<VerifyUserToolbar {...props} />}>
          <Typography variant="h6" gutterBottom>
            {`Verify User ${verificationTypeData.verificationType}`}
          </Typography>
          <Typography
              variant="subtitle1"
              style={{marginTop: '20px', maxWidth: '300px'}}
          >
            {`User ${verificationTypeData.verificationType} verification is failed with reason "${verificationTypeData.failReason}"`}
          </Typography>
          <TextInput
              name="adminApproveDescription"
              style={{marginTop: '20px'}}
              label="Verification Description"
              source="adminApproveDescription"
              validate={(required(), minLength(3), maxLength(100))}
          />
        </SimpleForm>
      </div>
  );

  return verificationTypeData && <VerifyUserForm/>;
};

