import { DeleteRowButton } from '@core/components';
import { resourceEnum } from '@core/enums';
import Typography from '@mui/material/Typography';
import {
  Datagrid,
  EmailField,
  Filter,
  List,
  ResourceContextProvider,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { ReinviteSmaButton } from './components/reinvite-sma-button.tsx';
import { RestoreAccessSmaButton } from './components/restore-access-sma-button.tsx';
import { SmaListActions } from './components/sma-list.action.tsx';
import { SuspendSmaButton } from './components/suspend-sma-button.tsx';

// NOTE: fix this filters later
const SmaFilter = props => (
  <Filter {...props}>
    <TextInput name="q" label="Search" source="q" alwaysOn />
  </Filter>
);

const Aside = () => (
  <div style={{ width: 200, margin: '1em' }}>
    <Typography variant="h6">Secro main admin details</Typography>
    <Typography variant="body2">
      Admins will only be published one an editor approves them
    </Typography>
  </div>
);

const ConditionalButtons = () => {
  const record = useRecordContext();
  const userId = localStorage.getItem('userId');
  if (record.id !== Number(userId)) {
    switch (record.status) {
      case 'invited':
        return (
          <>
            <ReinviteSmaButton disabled={false} name={'reinvite'} />
            <DeleteRowButton
              resource={resourceEnum.sma}
            />
          </>
        );
      case 'active':
        return (
          <>
            {record.status === 'active' ? (
              <SuspendSmaButton disabled={false} name={'suspend'} />
            ) : null}
            <DeleteRowButton
              resource={resourceEnum.sma}
            />
          </>
        );
      case 'suspended':
        return (
          <>
            {record.status === 'suspended' && (
              <RestoreAccessSmaButton
                disabled={false}
                name={'restore access'}
              />
            )}
            <DeleteRowButton
              resource={resourceEnum.sma}
            />
          </>
        );
    }
  } else {
    return null;
  }
  return null;
};

export const SmaList = props => {
  return (
    <ResourceContextProvider value={resourceEnum.sma}>
      <List
        empty={false}
        hasCreate={false}
        {...props}
        actions={<SmaListActions />}
        aside={<Aside />}
        filters={<SmaFilter />}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="role" />
          <TextField source="status" />
          <EmailField source="email" />
          <ConditionalButtons />
          <br />
        </Datagrid>
      </List>
    </ResourceContextProvider>
  );
};
