import { PhoneNumberInput } from '@components/phone-number-input.tsx';
import { AutocompleteDictionaryInput } from '@core/components';
import { optionsUrl, dictionaryEndpointsEnum } from '@core/enums';
import { fetchClient } from '@core/utils';
import { Card, CardContent, Typography } from '@mui/material';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { maxLength, minLength, required, SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin';

export const RAPCInvitationCompany = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const handleClick = async (data) => {
    fetchClient(optionsUrl.proxyAdminUrl + `/business`, {
      method: 'POST',
      body: JSON.stringify({
        businessName: data.businessName,
        taxRegistrationNumber: data.taxRegistrationNumber,
        ...(data.jurisdictionCode && {
          jurisdictionCode: data.jurisdictionCode,
        }),
        phoneNumber: data.phoneNumber || null,
        ...(data.country && {
          country: data.country,
        }),
        ...(data.address && {
          address: data.address,
        }),
        ...(data.stateOrRegion && {
          stateOrRegion: data.stateOrRegion,
        }),
      }),
    }, notify).then(data => {
      data.json().then(data => {
        if (data.status === 'error') {
          redirect('/rapc-admin/invitation-company');
          notify(`${data.message}`, { type: 'warning' });
        } else {
          redirect(
            '/rapc-admin/invitation-business',
            null,
            null,
            {},
            { data },
          );
          notify('Business successfully created!', {
            type: 'success',
          });
        }
      }).catch(function(e) {
        notify(e);
        redirect('/rapc-admin/invitation-company');
      });
    }).catch(() => {
      notify('Error: user suspension failed');
    });
  };

  return (
    <Card style={{ margin: '1em' }}>
      <CardContent>
        <SimpleForm onSubmit={handleClick} sx={{ maxWidth: 500 }}>
          <Typography variant="h6" gutterBottom>
            Invite RAPC Admin Company Info:
          </Typography>
          <TextInput
            name="businessName"
            validate={[required(), minLength(3), maxLength(80)]}
            source="businessName"
            fullWidth
          />
          <TextInput
            name="taxRegistrationNumber"
            validate={[required(), minLength(3), maxLength(100)]}
            source="taxRegistrationNumber"
            fullWidth
          />
          <TextInput
            name="stateOrRegion"
            source="stateOrRegion"
            validate={[minLength(3), maxLength(100)]}
            fullWidth
          />
          <TextInput
            name="address"
            source="address"
            validate={[minLength(3), maxLength(300)]}
            fullWidth
          />
          <PhoneNumberInput fullWidth source="phoneNumber" />
          <br />
          <AutocompleteDictionaryInput
            name="jurisdictionCode"
            source="jurisdictionCode"
            patch={dictionaryEndpointsEnum.jurisdictionCodes}
            fullWidth
            optionText="name"
            optionValue="code"
          />
          <AutocompleteDictionaryInput
            name="country"
            source="country"
            patch={dictionaryEndpointsEnum.countries}
            validate={[required()]}
            fullWidth
            optionText="name"
            optionValue="alpha_2"
          />
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

