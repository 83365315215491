import {optionsUrl, resourceEnum} from '@core/enums';
import {fetchClient} from '@core/utils';
import BlockIcon from '@mui/icons-material/Block';
import {useState} from 'react';
import {Button, useNotify, useRecordContext, useRedirect, useRefresh} from 'react-admin';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const DeactivateTemplateButton = ({disabled, name}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const deactivate = disabled ? true : loading;

  const handleClick = async () => {
    setLoading(true);
    try {
      await fetchClient(
          `${optionsUrl.proxyAdminUrl}/${resourceEnum.template}/${record.id}/status/deactivated`,
          {
            method: 'PATCH',
          },
        notify
      );
      notify('Template has been deactivated!', {type: 'success'});
      redirect(`/${resourceEnum.template}`);
      refresh();
    } catch (error) {
      notify(error.message, {type: 'warning'});
      redirect(`/${resourceEnum.template}`);
    } finally {
      setLoading(false);
    }
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to suspend',
      message: 'Are you sure to suspend the current template?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => await handleClick(),
        },
        {
          label: 'No',
          onClick: () => redirect(`/${resourceEnum.template}`),
        },
      ],
    });
  };

  return (
      <Button label={name} onClick={submit} disabled={deactivate}>
        <BlockIcon/>
      </Button>
  );
};
