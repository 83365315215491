import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {cloneElement} from 'react';
import {Button, sanitizeListRestProps, TopToolbar, useListContext, useRedirect} from 'react-admin';

/**
 * @NOTE
 * List ACtion component give us ability to customize
 * buttons on the toolbar on the top of list of records
 */

export const SmaListActions = props => {
  const {className, filters, ...rest} = props;
  const {resource, displayedFilters, filterValues, showFilter} =
      useListContext();
  const redirect = useRedirect();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
            cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}

        {/* Add your custom actions */}
        <Button
            onClick={() => {
              redirect('/sma-admin/invitation');
            }}
            label="invite SMA admin"
        >
          <AddCircleOutlineIcon/>
        </Button>
      </TopToolbar>
  );
};

