import { PhoneNumberInput } from '@components/phone-number-input.tsx';
import { optionsUrl } from '@core/enums';
import { fetchClient } from '@core/utils';
import { CorporateMailValidator } from '@core/validators/corporate-mail-validator.ts';
import { HumanNameValidator } from '@core/validators/human-name-validator.ts';
import { Card, CardContent, Typography } from '@mui/material';
import { email, maxLength, minLength, required, SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useLocation } from 'react-router-dom';
import { isLowerCaseEmailValidator } from '@core/validators';

export const RapcInvitationBusiness = () => {
  const location = useLocation();
  const redirect = useRedirect();
  const notify = useNotify();
  const handleClick = async (data) => {
    fetchClient(optionsUrl.proxyAdminUrl + `/rapc`, {
      method: 'POST',
      body: JSON.stringify({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber || null,
        email: data.email,
        businessId: location.state?.data?.id,
      }),
    }, notify).then(data => {
      data.json().then(function(data) {
        if (data.status === 'error') {
          redirect(
            '/rapc-admin/invitation-business',
            null,
            null,
            {},
            { data: location.state?.data },
          );
          notify(`${data.message}`, { type: 'warning' });
        } else {
          redirect(
            '/rapc-admin/invitation/confirmation',
            null,
            null,
            {},
            { data },
          );
          notify('Business successfully created!', {
            type: 'success',
          });
        }
      }).catch(function(e) {
        notify(e);
        redirect(
          '/rapc-admin/invitation-business',
          null,
          null,
          {},
          { data: location.state?.data },
        );
      });
    }).catch(() => {
      notify('Error: user suspension failed', { type: 'warning' });
    });
  };

  const handleSubmit = (event) => {
    confirmAlert({
      title: 'Confirm to Invite RAPC Admin',
      message: `Are you sure to invite current admin with mail ${event.email} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClick(event),
        },
        {
          label: 'No',
          onClick: () =>
            redirect(
              '/rapc-admin/invitation-business',
              null,
              null,
              {},
              { data: location.state?.data },
            ),
        },
      ],
    });
  };

  return (
    <Card style={{ margin: '1em' }}>
      <CardContent>
        <SimpleForm onSubmit={handleSubmit} sx={{ maxWidth: 500 }}>
          <Typography variant="h6" gutterBottom>
            Invite RAPC Admin Business Info:
          </Typography>
          <TextInput
            name="firstName"
            validate={[
              required(),
              minLength(3),
              maxLength(30),
              HumanNameValidator,
            ]}
            source="firstName"
            fullWidth
          />
          <TextInput
            name="lastName"
            validate={[
              required(),
              minLength(3),
              maxLength(30),
              HumanNameValidator,
            ]}
            source="lastName"
            fullWidth
          />
          <PhoneNumberInput fullWidth source="phoneNumber" />
          <br />
          <TextInput
            name="email"
            validate={[required(), email(), CorporateMailValidator, isLowerCaseEmailValidator]}
            value={email}
            source="email"
            fullWidth
          />
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

