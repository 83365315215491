import { optionsUrl, resourceEnum } from '@core/enums';
import { fetchClient} from '@core/utils';
import {
  AutocompleteInput,
  CreateProps,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

interface CreateToolbarProps {
  companyId: number;
  handleClose: () => void;
}

const CreateToolbar = ({ companyId, handleClose }: CreateToolbarProps) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const { reset, getValues } = useFormContext();

  const handleSave = async () => {
    const formValues = getValues();
    const templateId = formValues['templateId'];

    try {
      const response = await fetchClient(
        `${optionsUrl.proxyAdminUrl}/${resourceEnum.template}/${templateId}/company-reference/${companyId}`,
        {
          method: 'PATCH',
        },
      );
      if (response.ok) {
        notify('Template reference has been created!', { type: 'success' });
        handleClose(); // Close the modal after success
      }
      const { affected } = await response.json();
      if (affected) {
        notify('Template reference has been created!', { type: 'success' });
      } else {
        notify('Template reference alredy Exist!', { type: 'info' });
      }
    } catch (error) {
      console.error(error);
      notify('Template reference creating error', { type: 'warning' });
    } finally {
      redirect(`/${resourceEnum.company}/${companyId}`);
      refresh();
    }
  };

  return (
    <Toolbar>
      <SaveButton
        type="button"
        label="Save"
        variant="text"
        mutationOptions={{
          onSuccess: async () => {
            reset();
            window.scrollTo(0, 0);
            await handleSave();
          },
        }}
      />
    </Toolbar>
  );
};

interface CreateReferenceTemplateProps extends CreateProps {
  companyId: number;
  companyName: string;
  handleClose: () => any;
}

export const CreateReferenceTemplate = ({ companyId, companyName, handleClose }: CreateReferenceTemplateProps) => (
  <div style={{ margin: '1px' }}>
    <div>
      From company: <b>{companyName}</b>
    </div>
    <div style={{ marginTop: '10px' }}>to:</div>
    <SimpleForm toolbar={<CreateToolbar companyId={companyId} handleClose={handleClose} />}>
      <ReferenceInput label="Template" name="templateId" filter={{isPrivate:true, status: 'Published'}} source='' reference={resourceEnum.template} >
        <AutocompleteInput
          name="templateId"
          source="Template"
          optionText="shortName"
          style={{ width: 500 }}
          filterToQuery = {(searchText:string) => ({ q: `${searchText}`, })}
        />
      </ReferenceInput>
    </SimpleForm>
  </div>
);
