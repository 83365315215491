import {cloneElement} from 'react';
import {sanitizeListRestProps, TopToolbar, useListContext} from 'react-admin';

export const TemplateListActions = (props) => {
  const {className, filters, ...rest} = props;
  const {resource, displayedFilters, filterValues, showFilter} =
      useListContext();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
            cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
      </TopToolbar>
  );
};


