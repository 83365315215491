import {resourceEnum, userRoleEnum} from '@core/enums';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import BusinessIcon from '@mui/icons-material/Business';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Box from '@mui/material/Box';
import {useState} from 'react';
import {MenuItemLink, MenuProps, usePermissions, useSidebarState} from 'react-admin';
import SubMenu from './SubMenu';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({dense = false}: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const [open] = useSidebarState();
  const {permissions} = usePermissions();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({...state, [menu]: !state[menu]}));
  };

  return (
      <Box
          sx={{
            width: open ? 200 : 50,
            marginTop: 1,
            marginBottom: 1,
            transition: theme =>
                theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
          }}
      >
        <MenuItemLink
            to={resourceEnum.company}
            state={{_scrollToTop: true}}
            primaryText="Companies"
            title="Companies"
            leftIcon={<BusinessIcon/>}
            dense={dense}
        />
        <MenuItemLink
            to={resourceEnum.user}
            state={{_scrollToTop: true}}
            primaryText="Users"
            title="Users"
            leftIcon={<GroupIcon/>}
            dense={dense}
        />
        <MenuItemLink
            to={resourceEnum.rapc}
            state={{_scrollToTop: true}}
            primaryText="Rapc"
            title="Rapc"
            leftIcon={<EmojiPeopleIcon/>}
            dense={dense}
        />
        {permissions === userRoleEnum.SuperAdmin && (
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="Super-Admin"
                icon={<AdminPanelSettings/>}
                dense={dense}
            >
              <MenuItemLink
                to={resourceEnum.analytic}
                state={{_scrollToTop: true}}
                primaryText="Analytics"
                title="Analytics"
                leftIcon={<QueryStatsIcon/>}
                dense={dense}
              />
              <MenuItemLink
                  to={resourceEnum.sma}
                  state={{_scrollToTop: true}}
                  primaryText="SMA"
                  title="Secro Main Admin (SMA)"
                  leftIcon={<PersonIcon/>}
                  dense={dense}
              />
              <MenuItemLink
                  to={resourceEnum.template}
                  state={{_scrollToTop: true}}
                  primaryText="Templates"
                  title="Contract templates"
                  leftIcon={<LineStyleIcon/>}
                  dense={dense}
              />
              <MenuItemLink
                to={resourceEnum.featureFlag}
                state={{_scrollToTop: true}}
                primaryText="Feature Flag"
                title="Feature Flag"
                leftIcon={<EmojiFlagsIcon/>}
                dense={dense}
              />
            </SubMenu>
        )}
      </Box>
  );
};

export default Menu;
