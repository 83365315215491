import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useState, ReactNode } from 'react';

interface CreateInDialogButtonProps {
  children: (handleClose: () => void) => ReactNode;
  title: string;
  label?: string;
}

const CreateDialog = ({ open, onClose, title, children }) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle
      id="form-dialog-title"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <h5>{title}</h5>
      <IconButton aria-label="close" onClick={onClose} sx={{ color: (theme) => theme.palette.grey[500] }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      {children(onClose)}
    </DialogContent>
  </Dialog>
);

export const CreateInDialogButton = ({ children, title, label = 'Add' }: CreateInDialogButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        onClick={handleClickOpen}
        color="primary"
        startIcon={<AddIcon />}
        style={{marginLeft: '5px', maxWidth: '20px'}}
      >
        {label}
      </Button>
      <CreateDialog open={open} onClose={handleClose} title={title}>
        {children}
      </CreateDialog>
    </>
  );
};
