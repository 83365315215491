import { TextInput } from 'react-admin';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const PhoneNumberInput = props => {
    const isValid = value => {
        if (!value || isValidPhoneNumber(value)) {
            return;
        } else {
            return 'Invalid phone number';
        }
    };

    return (
        <TextInput
            validate={props.validate ?? [isValid]}
            parse={val => {
                if (!val) {
                    return null;
                } else {
                    return val[0] === '+'
                        ? val.replace(/[^0-9.+]/g, '')
                        : `+${val.replace(/[^0-9.]/g, '')}`;
                }
            }}
            {...props}
        />
    );
};

