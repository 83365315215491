import { optionsUrl } from '@core/enums';
import { httpHeader } from '@core/utils';
import { Card, CardContent, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Form, required, TextInput, useLogin, useNotify, useSafeSetState, useTranslate } from 'react-admin';

const theme = createTheme({
  palette: {
    primary: {
      main: '#026062',
    },
  },
});

export const MyLoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const translate = useTranslate();
  const [envs, setEnvs] = useState<string[]>([]);
  const [env, setEnv] = useState<string>('local');
  const login = useLogin();
  const notify = useNotify();
  const [loading, setLoading] = useSafeSetState(false);

  function handleSubmit() {
    login({ username, password, env }).catch(() => {
        setLoading(true);
        notify('Invalid email or password');
      },
    ).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    const fetchTypes = () => {
      fetch(`${optionsUrl.proxyUrl}/envs`, {
        method: 'GET',
        headers: httpHeader(),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        setEnvs(data);
      })
      .catch(error => {
        console.error('There was an error fetching types!', error);
      });
    };

    fetchTypes();
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Form
          onSubmit={handleSubmit}
          mode="onChange"
          noValidate
        >
          <Card style={{ margin: '1em', width: "400px" }}>
          <CardContent>
            <Typography component="h1" variant="h4"  style={{display: 'flex'}}>
              <span style={{display: 'flex'}}>Login to Tech Admin</span>
            </Typography>
            <br />
            <TextInput
              autoFocus
              name="username"
              source="username"
              label={translate('ra.auth.username')}
              autoComplete="username"
              validate={required()}
              onChange={e => setUsername(e.target.value)}
              fullWidth
            />
            <TextInput
              name="password"
              source="password"
              label={translate('ra.auth.password')}
              type="password"
              autoComplete="current-password"
              validate={required()}

              onChange={e => setPassword(e.target.value)}
              fullWidth
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="env-label">ENV</InputLabel>
              <Select
                labelId="env-label"
                label="Env"
                defaultValue={env}
                required
                onChange={e => setEnv(e.target.value)}
              >
                {envs.map((env) => (
                  <MenuItem key={env} value={env}>
                    {env}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 4, mb: 6 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  size={19}
                  thickness={3}
                />
              ) : (
                translate('ra.auth.sign_in')
              )}
            </Button>
          </CardContent>
          </Card>
        </Form>
      </Box>
    </Container>
    </ThemeProvider>
  );
};
