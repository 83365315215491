import { optionsUrl } from '@core/enums';
import { fetchClient } from '@core/utils';
import ReplayIcon from '@mui/icons-material/Replay';
import { useState } from 'react';
import { Button, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const ReinviteSmaButton = ({ disabled, name }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const deactivate = disabled || loading;

  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await fetchClient(`${optionsUrl.proxyAdminUrl}/sma/${record.id}/reinvite`, {
        method: 'POST',
      }, notify);
      const data = await response.json();
      if (!response.ok) {
        notify(`${data.message}`, { type: 'warning' });
        return;
      }
      redirect(
        '/sma-admin/invitation/confirmation',
        null,
        null,
        {},
        { data },
      );
      notify('User Reinvited!', { type: 'success' });
    } catch (error) {
      notify('Error: user invitation failed', { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirm to reinvite',
      message: 'Are you sure to reinvite the current admin?',
      buttons: [
        {
          label: 'Yes',
          onClick: handleClick,
        },
        {
          label: 'No',
          onClick: () => redirect('/sma'),
        },
      ],
    });
  };

  return (
    <Button
      label={name}
      onClick={submit}
      disabled={deactivate}
    >
      <ReplayIcon />
    </Button>
  );
};
