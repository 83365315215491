import { CreateInDialogButton } from '@core/components';
import { adminEndpointsEnum, clmProviderChoices, clmProviderEnum, resourceEnum } from '@core/enums';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CreateReferenceCompany } from '@resources/template/components/create-reference-company.tsx';
import { DeleteReferenceCompanyButton } from '@resources/template/components/delete-reference-company-button.tsx';
import { UploadTemplateFile } from '@resources/template/components/upload-template-file.tsx';
import {
  Button,
  ChipField,
  Datagrid,
  DateField,
  Edit,
  maxLength,
  minLength,
  ReferenceManyField,
  required,
  SaveButton, SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
  useRedirect,
  useRefresh,
  WithRecord,
} from 'react-admin';

const TemplateEditToolbar = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
    <Toolbar {...props}>
      <SaveButton
        type="button"
      />
      <div style={{ margin: '1em' }}>
        <Button
          label={'Cancel'}
          onClick={() => {
            redirect(`/${resourceEnum.template}`);
            refresh();
          }}
          variant="contained"
          size="medium"
        >
          <CancelIcon />
        </Button>
      </div>
    </Toolbar>
  );
};

const TemplateReference = ({record}) => {
  if (!record) return null;
  return <>
    <br />
    <Typography component="h1" variant="h5">
      Companies:
    </Typography>

    <ReferenceManyField
      label="Сompanies"
      reference={adminEndpointsEnum.templateCompanyReference}
      target="templateId"
      perPage={10}
    >
      <Datagrid
        bulkActionButtons={false}
      >
        <TextField source="id" />
        <TextField source="companyName" />
        <ChipField source="status" />
        <DateField source="deactivatedAt" />
        <DeleteReferenceCompanyButton
          templateId={record.id}
          name="Delete"
        />
      </Datagrid>
    </ReferenceManyField>
    <WithRecord
      render={record => (
        <CreateInDialogButton
          title="Add Company Reference"
        >
          {handleClose => (
            <CreateReferenceCompany
              templateId={record.id}
              templateName={record.shortName}
              handleClose={handleClose}
            />
          )}
        </CreateInDialogButton>
      )}
    />
  </>;
};

export const TemplateEditForm = () => {
  const record = useRecordContext();
  const isPdfTemplate = record?.clmProvider === clmProviderEnum.PdfForms;

  return (
    <>
        <TabbedForm
          defaultValues={{ average_note: 0 }}
          warnWhenUnsavedChanges
          toolbar={<TemplateEditToolbar />}
        >
          <TabbedForm.Tab label="Summary">
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              justifyContent="space-between"
            >
              <TextInput name="id" source="id" disabled style={{ width: 100 }} />
              <TextInput name="status" source="status" disabled style={{ width: 300 }} />
              <TextInput name="shortName" source="shortName" disabled style={{ width: 600 }} />
              <TextInput
                name="name"
                source="name"
                validate={[
                  required(),
                  minLength(3),
                  maxLength(80),
                ]}
              />
              <SelectInput
                source="clmProvider"
                style={{ width: 200 }}
                emptyValue={0}
                choices={clmProviderChoices}
              />
              <TemplateReference record={record} />
            </Box>
          </TabbedForm.Tab>

          <TabbedForm.Tab label="upload" disabled={!isPdfTemplate}>
            <UploadTemplateFile record={record} />
          </TabbedForm.Tab>


          <TabbedForm.Tab label="Header" disabled={isPdfTemplate}>
            <TextInput
              name="header"
              source="header"
              validate={[
                required(),
              ]}
              multiline={true}
              type={'text'}
              fullWidth
            />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Conditions Of Carriage" disabled={isPdfTemplate}>
            <TextInput
              name="conditionsOfCarriage"
              source="conditionsOfCarriage"
              validate={[
                required(),
              ]}
              multiline={true}
              type={'text'}
              fullWidth
            />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Body" disabled={isPdfTemplate}>
            <TextInput
              name="body"
              source="body"
              validate={[
                required(),
              ]}
              multiline={true}
              type={'text'}
              fullWidth
            />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Footer" disabled={isPdfTemplate}>
            <TextInput
              name="footer"
              source="footer"
              multiline={true}
              type={'text'}
              fullWidth
            />
          </TabbedForm.Tab>
        </TabbedForm>
    </>
  );
};

export const TemplateEdit = (props) => (
  <Edit {...props}>
    <TemplateEditForm />
  </Edit>
);
