import { TruncatedTextField } from '@core/components';
import {userStatusEnum} from '@core/enums';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {Fragment, useState} from 'react';
import {Datagrid, DateField, EmailField, TextField, useListContext} from 'react-admin';

export const RapcListTabbedDatagrid = () => {
  const context: any = useListContext();

  const [tabValue, setTabValue] = useState(() =>
      context.filterValues && context.filterValues.status === 'deactivated'
          ? 'deactivated'
          : 'active',
  );
  const tabs = [
    {id: 'active', name: 'Active'},
    {id: 'deactivated', name: 'Deactivated'},
  ];

  const handleChange = (_: React.SyntheticEvent, value: any) => {
    if (value === tabValue) {
      return;
    } else if (value === 'active') {
      setTabValue('active');
      context.setFilters({
        ...context.filterValues,
        status: null,
      });
    } else {
      setTabValue(userStatusEnum.Deactivated);
      context.setFilters({
        ...context.filterValues,
        status: userStatusEnum.Deactivated,
        role: null,
      });
    }
  };

  return (
      <Fragment>
        <Tabs
            centered
            indicatorColor="primary"
            onChange={handleChange}
            value={tabValue}
        >
          {tabs.map(choice => (
              <Tab
                  key={choice.id}
                  label={choice.name}
                  value={choice.id}
              />
          ))}
        </Tabs>
        <Divider/>
        <Datagrid rowClick="edit">
          <TextField source="id"/>
          <EmailField source="email"/>
          <TruncatedTextField source="firstName" maxLength={30} />
          <TruncatedTextField source="lastName" maxLength={30} />
          <TruncatedTextField source="companyName" maxLength={30} />
          <TextField source="companyStatus"/>
          <DateField source="createdAt"/>
          <TextField source="status"/>
          {tabValue === userStatusEnum.Deactivated && (
              <DateField source="deactivatedAt"/>
          )}
          <TextField source="onboardingStatus"/>
          <br/>
        </Datagrid>
      </Fragment>
  );
};
