import 'react-confirm-alert/src/react-confirm-alert.css';
import { resourceEnum } from '@core/enums';
import { AutocompleteInput, AutocompleteInputProps, FilterPayload, ReferenceInput } from 'react-admin';

export const AutocompleteRefInput = (props: AutocompleteInputProps & {
  reference: resourceEnum,
  label: string;
  filterToQuery?: (searchText: string) => any;
  debounce?: number;
  refFilter?: FilterPayload;
}) => {
  const optionValue = props.optionValue || 'id';
  const optionText = props.optionText || 'name';
  const filterToQuery = props.filterToQuery ? props.filterToQuery : (searchText: string) => ({ q: `${searchText.trim()}` });

  return (
    <ReferenceInput label={props.label}
                    name={props.name} source=""
                    debounce={props.debounce}
                    filter={props.refFilter}
                    reference={props.reference}>
      <AutocompleteInput
        {...props}
        optionValue={optionValue}
        optionText={optionText}
        filterToQuery={filterToQuery}
        emptyText="--"
      />
    </ReferenceInput>
  );
};
