import { TruncatedTextField } from '@components/truncated-text-field.tsx';
import { companyStatusEnum, companyTypeEnum, userStatusEnum } from '@core/enums';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Fragment, useState } from 'react';
import {
  ArrayField,
  ChipField,
  DatagridConfigurable,
  DateField,
  EmailField,
  SimpleList,
  TextField,
  useListContext,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import { DeleteCompanyButton } from '../components/delete-company-button';
import { InviteCompanyButton } from '../components/invite-company-button';
import { ReinviteCompanyButton } from '../components/reinvite-company-button';
import { RestoreAccessCompanyButton } from '../components/restore-access-company-button';
import { SuspendCompanyButton } from '../components/suspend-access-company-button';

let isTradingPartner: boolean;

const ConditionalButtons = () => {
  const record = useRecordContext();

  const isTradingPartner =
    record.status === companyStatusEnum.Invited &&
    (record.type === companyTypeEnum.Indorsee || record.type === companyTypeEnum.TradingPartner);

  const getButtons = () => {
    if(record.status && record.rapcId !== 1){
    switch (record.status) {
      case companyStatusEnum.Invited:
        if (record.type === companyTypeEnum.Regular) {
          return <ReinviteCompanyButton disabled={false} name="reinvite" />;
        }
        if (isTradingPartner) {
          return <InviteCompanyButton disabled={record.rapcId !== 1} name="Invite as Regular Account" />;
        }
        break;
      case companyStatusEnum.Suspended:
      case companyStatusEnum.Deactivated:
        return <RestoreAccessCompanyButton disabled={false} name="restore access" />;
      case companyStatusEnum.Trusted:
      case companyStatusEnum.Unverified:
        return (
          <>
            <SuspendCompanyButton disabled={false} name="suspend" />
            <DeleteCompanyButton disabled={isTradingPartner} name="Delete" />
          </>
        );
      default:
        return null;
    }
    }
  };

  return <>{getButtons()}</>;
};

export const TabbedDatagrid = (props) => {
  const context: any = useListContext();

  const [tabValue, setTabValue] = useState(() => {
    switch (true) {
      case context.filterValues &&
      context.filterValues.status === companyStatusEnum.Deactivated:
        return 'deactivated';
      case context.filterValues && isTradingPartner:
        return 'customerLeads';
      default:
        return 'active';
    }
  });

  const tabs = [
    { id: 'active', name: 'Active' },
    { id: 'deactivated', name: 'Deactivated' },
    { id: 'customerLeads', name: 'Customer leads' },
  ];

  const handleChange = (_: React.SyntheticEvent, value: any) => {
    switch (value) {
      case 'deactivated':
        setTabValue(value);
        context.setFilters({
          ...context.filterValues,
          status: companyStatusEnum.Deactivated,
          type: null,
          tabName: value,
        });
        break;
      case 'customerLeads':
        setTabValue(value);
        context.setFilters({
          ...context.filterValues,
          status: companyStatusEnum.Invited,
          type: [
            companyTypeEnum.Indorsee,
            companyTypeEnum.TradingPartner,
          ],
          tabName: value,
        });
        break;
      default:
        setTabValue(value);
        context.setFilters({
          ...context.filterValues,
          status: null,
          type: companyTypeEnum.Regular,
          tabName: value,
        });
        break;
    }
  };
  const StyledDatagrid = styled(DatagridConfigurable)(({ theme }) => ({
    '& .companyName': {
      maxWidth: '16em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .hiddenOnSmallScreens': {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
  }));

  return (
    <Fragment>
      <Tabs
        centered
        value={tabValue}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map(choice => (
          <Tab
            key={choice.id}
            label={choice.name}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <StyledDatagrid {...props} rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TruncatedTextField source="companyName" maxLength={30} />
        <TruncatedTextField source="rapcFirstName" maxLength={30} />
        <TruncatedTextField source="rapcLastName" maxLength={30} />
        <ChipField source="status" />
        <ArrayField
          sortable={false}
          label="Onboarding Statuses"
          source="companyVerifications"
        >
          <SimpleList
            primaryText={record => `${record.verificationType}-`}
            secondaryText={record => `${record.verificationStatus}`}
            rowSx={() => ({
              '& div': {
                display: 'flex',
              },
              border: '1px lightgray groove',
              borderRadius: '30px',
              height: '30px',
            })}
          />
        </ArrayField>
        <EmailField source="rapcEmail" />
        <TextField source="taxRegistrationNumber" />
        <WrapperField label="Provider" sortBy="providerId">
          <TextField source="provider.name" />
        </WrapperField>
        <DateField source="createdAt" />
        {tabValue === userStatusEnum.Deactivated && (
          <DateField source="deactivatedAt" />
        )}
        <ConditionalButtons />
        <br />
      </StyledDatagrid>
    </Fragment>
  );
};
