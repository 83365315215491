import {createTheme} from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#026062',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#026062',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    mode: 'light',
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#fff',
          color: '#063807',
          boxShadow: 'none',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:hover:active::after': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'currentColor',
            opacity: 0.3,
            borderRadius: 'inherit',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#fff',
          backgroundColor: '#026062',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
      head: {
        "& > th ": {
          color: '#606069',
          fontWeight: 'bold',
        }
        }
      },
    }
  },
});
