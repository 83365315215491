import {HttpStatus, optionsUrl, userRoleEnum} from '@core/enums';
import { httpHeader } from '@core/utils';
import {AuthProvider as ReactAdminAuthProvider } from 'react-admin';

export const authProvider:ReactAdminAuthProvider  = {
  async login({ username, password, env }): Promise<{ redirectTo?: string | boolean } | void | any>  {
    const request = new Request(optionsUrl.proxyAdminUrl + '/login', {
      method: 'POST',
      body: JSON.stringify({email: username, password}),
      headers: httpHeader({'env-name':env}),
    });
    await fetch(request).then(async response => {
      const data = await response.json();
      if (response.status !== HttpStatus.OK) {
        throw new Error(data.message);
      }
      localStorage.setItem('userId', JSON.stringify(data.id));
      return data;
    }).then(auth => {
      const role = auth['role'] as userRoleEnum;
      if (!role?.length || ![userRoleEnum.SecroMainAdmin, userRoleEnum.SuperAdmin].includes(role)) {
        console.warn(`Wrong role ${role}`);
        throw new Error('Non acceptable role');
      }
      localStorage.setItem('auth', JSON.stringify(auth));
      localStorage.setItem('role', role);
      localStorage.setItem('env', env);
      // window.location.reload();
    }).catch(error => {
      throw new Error(error);
    });
  },
  async checkError<T extends {status?:number}>(error:T):Promise<void> {
    const status = error.status;
    if (!status || status === HttpStatus.UNAUTHORIZED || status === HttpStatus.FORBIDDEN) {
      localStorage.removeItem('auth');
      return Promise.reject({message: false});
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () =>
      localStorage.getItem('auth')
          ? Promise.resolve()
          : Promise.reject({message: 'login.required'}),
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const {id, fullName, avatar} = JSON.parse(
          localStorage.getItem('auth'),
      );
      return Promise.resolve({id, fullName, avatar});
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
