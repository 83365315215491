import { HttpStatus } from '@core/enums';
import { useNotify } from 'react-admin';


export const ErrorHandler = (statusCode: HttpStatus, notify?: ReturnType<typeof useNotify>) => {
  console.error(`ErrorHandler! [statusCode:${statusCode}]`);
  switch (statusCode) {
    case HttpStatus.UNAUTHORIZED:
    case HttpStatus.FORBIDDEN:
      if (notify) {
        notify('Unauthorized!', { type: 'error' });
      } else {
        console.log('Unauthorized!');
      }
      localStorage.removeItem('auth');
      localStorage.removeItem('env');
      localStorage.removeItem('role');
      localStorage.removeItem('userId');
      window.location.reload();
      break;
    case HttpStatus.UNPROCESSABLE_ENTITY:
      if (notify) {
        notify('Validation error', { type: 'warning' });
      } else {
        console.log('Validation error');
      }
      break;
    case HttpStatus.INTERNAL_SERVER_ERROR:
    default:
      if (notify) {
        notify('Backend error', { type: 'error' });
      } else {
        console.log('Backend error');
      }
      break;
  }
};
