export enum userOnboardingStatusEnum{
    tempPassword= 'tempPassword',
    userProfile= 'userProfile',
    twoFA= 'twoFA',
    companyProfile= 'companyProfile',
    kybCheck= 'kybCheck',
    amlBusinessCheck= 'amlBusinessCheck',
    bavCheck= 'bavCheck',
    kycCheck= 'kycCheck',
    amlIndividualCheck= 'amlIndividualCheck',
}
