import {optionsUrl} from '@core/enums';
import {fetchClient} from '@core/utils';
import {yupResolver} from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useNotify, useRedirect} from 'react-admin';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object({
  password: yup.string().min(8).max(16).required(),
  confirmPassword: yup.string().min(8).max(16).required().oneOf([yup.ref('password'), null], 'Passwords must match'),
  firstName: yup.string().min(3).max(30).required(),
  lastName: yup.string().min(3).max(30).required(),
}).required();

const theme = createTheme({
  palette: {
    primary: {
      main: '#026062',
    },
  },
});

export const Setup = () => {
  const location = useLocation();
  const {
    register,
    handleSubmit: handleValidation,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema)});
  const redirect = useRedirect();
  const notify = useNotify();
  const handleSubmit = async event => {
    fetchClient(optionsUrl.proxyAdminUrl + `/sma/setup`, {
      method: 'POST',
      body: JSON.stringify({
        firstName: event.firstName,
        lastName: event.lastName,
        password: event.password,
        confirmPassword: event.confirmPassword,
      }, notify),
    }).then(data => {
      data.json().then(function(data) {
        if (data.status === 'error') {
          redirect('/setup');
          notify(`check your inputs`, {type: 'warning'});
        } else {
          redirect('/login');
          notify('Admin successfully registered!', {
            type: 'success',
          });
        }
      }).catch(function(e) {
        notify(e);
        redirect('/setup');
      });
    }).catch(() => {
      notify('Error: user suspension failed');
    });
  };

  return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
          >
            <img width={56} src="../../public/brand-logo.png" alt="Semy Sharp"/>

            <Typography component="h1" variant="h5">
              Welcome to Secro
            </Typography>

            <Typography component="h1" variant="h6">
              Please fill the form
            </Typography>
            <Box
                component="form"
                noValidate
                onSubmit={handleValidation(handleSubmit)}
                sx={{mt: 3}}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      {...register('firstName', {
                        required: true,
                      })}
                      defaultValue={
                        location.state?.data?.firstName
                      }
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      error={!!errors?.firstName}
                      helperText={errors.firstName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      {...register('lastName', {
                        required: true,
                      })}
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      defaultValue={
                        location.state?.data?.lastName
                      }
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      {...register('password', {
                        required: true,
                      })}
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      {...register('confirmPassword', {
                        required: true,
                      })}
                      required
                      fullWidth
                      name="confirmPassword"
                      label="confirmPassword"
                      type="password"
                      id="confirmPassword"
                      error={
                        !!errors.confirmPassword
                      }
                      helperText={errors.confirmPassword?.message}
                  />
                </Grid>
              </Grid>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
  );
};

